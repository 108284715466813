<template>
    <Paginator :rows="this.pagination.pageSize" :totalRecords="this.pagination.total" :rowsPerPageOptions="[10, 20, 50, 99999]" @page="onMoveNewPage" style="max-height: 300px;">
</Paginator>

</template>
<script>
import Paginator from 'primevue/paginator';
export default {
  data() {
      return {
       
      }
  },
  props: {
    pagination: {},
  },
  components: {
  	Paginator, 
  },
  created(){},
  methods: {
    onMoveNewPage(state){
        this.$emit('page-change', state.page+1, state.rows);
    },
  },
};
</script>

<style scoped>
</style>
