<template>
	<Dialog v-model:visible="visible" maximizable modal header="사용자 정보 등록" :style="{ width: '35vw' }">
		  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
	      	<Divider align="left" type="solid">
		    	<b>이름</b>
			</Divider>
			<p>
			    <input type="text" class="form-control" placeholder="한글로 작성해주세요." v-model="name" />
			</p>
			<Divider align="left" type="solid">
		    	<b>이메일</b>
			</Divider>
			<p>
		        <input type="text" class="form-control" placeholder="영문으로 작성해주세요." v-model="email"/>
			</p>
			<Divider align="left" type="solid">
		    	<b>사용자 여부</b>
			</Divider>
			<p>
			    <input class="ms-2" type="checkbox" id="userCheck" v-model="useYn" true-value="Y" false-value="N" />
			    <label for="userCheck">적용</label>
			</p>
			<Divider align="left" type="solid">
		    	<b>관리자 여부</b>
			</Divider>
			<p>
			    <input class="ms-2" type="checkbox" id="managerCheck" v-model="adminYn" true-value="Y" false-value="N"/>
			    <label for="managerCheck">적용</label>
			</p>
			
	      </div>
	      <template #footer>
	        <Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
	        <Button label="등록" icon="pi pi-check" @click="regist" autofocus />
	    </template>
	</Dialog>
</template>

<script>
export default {
  name: 'registModal',
  data(){
    return{
      name: "",
      email: "",
      useYn: "",
      adminYn: "",      
      visible: true,
    }
  },
  methods: {
    regist () {
      	if(confirm("사용자 정보를 등록하시겠습니까?")){
	        if(this.name == "" || this.email == "") {
	          this.$messageShow('이름, 이메일주소는 필수입력항목입니다.', 'warn');
	        }else{
	        
		        if(!this.$emailValidation(this.email)){
		        	this.$messageShow('이메일주소가 형식에 맞지 않습니다.', 'warn');
		        }else{
		        
			        var param = {
				        "userEmail": this.email,
				        "name": this.name,
				        "useYn": this.useYn,
				        "adminYn": this.adminYn,
					};
					
					this.$post('/biz/account/add', param,
		                function(response, i){
		                	//alert("사용자 정보 등록이 완료되었습니다.");
		                	i.$messageShow('사용자 정보 등록이 완료되었습니다.', 'success');
		                	i.visible = false;
		                	i.$emit('closeRegistModal');
	          				i.$emit('update');
		                },
		                function(e, i){
		                	i.$messageShow(e, 'danger');
		                }
		      		);
			 	}	        
			}
	    }
    }
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>