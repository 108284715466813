<template>
  <div>첫 화면</div>
</template>

<script>
import axios from 'axios';
import { mapGetters } from "vuex";

var requestOptions = {
  headers: {
    'Content-Type': 'application/json',
    timeout: 60000
  }
}

export default {
  name: 'Basics',
  created() {
  	var paramData = {};
    axios.get(`/biz/common/${this.$store.getters.getUserEmail}/${this.$store.getters.getRole}/menu`, paramData, requestOptions)
      .then((result) => {        
        //this.$router.push({name:result.data.result[0].menuUrl.split('/')[2], params: {menuSeq: result.data.result[0].menuSeq}});
        this.$router.push({name:result.data.subMenus[0].menuUrl.split('/')[2], params: {menuSeq: result.data.subMenus[0].menuSeq}});        
    })
  },
}
</script>

<style scoped>
div {
    width: 100%;
    height: 100%;
    
    text-align: center;
    font-size: 40px;
  } 
</style>