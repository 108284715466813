<template>	
  <ListNoRequest :isMobile="isMobile" :engineerRoleCheck="engineerRoleCheck"/>
</template>

<script>
import ListNoRequest from './ListNoRequest.vue'
import axios from 'axios'
var requestOptions = {
  headers: {
    'Content-Type': 'application/json',
    timeout: 60000
  }
};
export default {
  name: 'LicenseMain',
  data() {
    return {
      engineerRoleCheck: false, 
      isMobile : this.$isMobile(),
    };
  },
  props: {
    menuSeq: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ListNoRequest,
  },
  created() {    

  	var paramData = {};
    //팀 or 관리자 권한 체크
    this.$post(`/biz/account/${this.$store.getters.getUserEmail}/team/get`, paramData,
          function(response, i){           
            var strTeamCode = response.data.teamCode;
            if (strTeamCode != "" || strTeamCode != null || strTeamCode != undefined) {
              strTeamCode = strTeamCode.substring(1, 3);  
              if ( strTeamCode == 'ST' || response.data.teamCode == 'MAC' || response.data.teamCode == 'MMS') {
                i.engineerRoleCheck = true;
              } else {
                i.engineerRoleCheck = false;
              }         
            } else {
              i.engineerRoleCheck = false;
            } 

          },
          function(e, i){
            console.log(e);
          }
    );

    axios.get(`/biz/common/${this.$store.getters.getUserEmail}/${this.menuSeq}/menu-auth`, paramData, requestOptions)
      .then((result) => {        
	      if(result.data.permissionType == 'A'){
	      	this.engineerRoleCheck = true;
	      }else{
	      	this.engineerRoleCheck = false;  
	      }
    });    
  },
  mounted(){
    this.onResize();
    window.addEventListener('resize',this.onResize,{passive:true});
  },
  beforeDestory(){
    if(typeof window === 'undefined') return;
    window.removeEventListener('resize',this.onResize,{passive:true});
  },
  methods: {
  	onResize(){
      this.isMobile = window.innerWidth < 600
    },
  },
};
</script>

<style lang="css" scoped>

</style>