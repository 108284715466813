<template>
<Toast position="center" />
  <Header />
  <div class="container-fluid">
  	<div class="row">
	  <Menu />
	  <Content />
    </div>
  </div>
  
  <Dialog v-model:visible="globalSessionTimeoutVisible" modal header="Header" :style="{ width: '30vw' }">
    <p>
        세션이 만료되어 로그아웃됩니다.
    </p>
    <template #footer>
        <Button label="확인" icon="pi pi-check" @click="visible = false; this.$logout();" autofocus />
    </template>
  </Dialog>
</template>
    
<script>
import Header from './HeaderMain.vue'
import Menu from './MenuMain.vue'
import Content from './ContentMain.vue'

import { mapGetters } from "vuex"
export default {
  name: 'Main',
  components: {
  	Header,
    Menu,
    Content,
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  data() {
    return {
    	globalSessionTimeoutVisible : false,
    }
  },
  created() {
  	if(!this.isAuthenticated){
  		this.globalSessionTimeoutVisible = true;
  	}
  },
}
</script>
    
<style scoped>
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}

.bi {
  vertical-align: -.125em;
  fill: currentColor;
}

.nav-scroller {
  position: relative;
  z-index: 2;
  height: 2.75rem;
  overflow-y: hidden;
}

.nav-scroller .nav {
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 1rem;
  margin-top: -1px;
  overflow-x: auto;
  text-align: center;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

@import './dashboard.css';
</style>