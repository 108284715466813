<template>
  <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light collapse sidebar">
    <div class="position-sticky pt-3 sidebar-sticky d-flex" style="flex-flow: column; justify-content: space-between;">
      <div>
      <hr style="margin:0 rem">
      <!-- <ul class="nav nav-pills flex-column mb-auto">
        <template v-for="(menu) in sortedMenu" :key="menu.menuSeq">      
            <li class="nav-item" v-if="isMobile === false || (isMobile === true && menu.systemType !== 'A')" @click="topMenuButtonClick">
              <router-link class="nav-link" active-class="active" :to="{name:menu.menuUrl.split('/')[2], params: {menuSeq: menu.menuSeq}} ">  -->
                <!-- <svg v-if="menu.menuName === '라이선스 복호화'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-unlock" viewBox="0 0 16 16">              
                  <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2M3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1z"/>
                </svg>           
                <svg v-else-if="menu.menuName === '구 라이선스 내역'" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-house-slash" viewBox="0 0 16 16">              
                  <path d="M13.879 10.414a2.5 2.5 0 0 0-3.465 3.465zm.707.707-3.465 3.465a2.501 2.501 0 0 0 3.465-3.465m-4.56-1.096a3.5 3.5 0 1 1 4.949 4.95 3.5 3.5 0 0 1-4.95-4.95Z"/>
                  <path d="M7.293 1.5a1 1 0 0 1 1.414 0L11 3.793V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v3.293l2.354 2.353a.5.5 0 0 1-.708.708L8 2.207l-5 5V13.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 1 0 1h-4A1.5 1.5 0 0 1 2 13.5V8.207l-.646.647a.5.5 0 1 1-.708-.708z"/>
                </svg>
                <svg v-else xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-play-fill" viewBox="0 0 16 16">
                  <path d="m11.596 8.697-6.363 3.692c-.54.313-1.233-.066-1.233-.697V4.308c0-.63.692-1.01 1.233-.696l6.363 3.692a.802.802 0 0 1 0 1.393z"/>
                </svg> -->
                <!-- {{ menu.menuName }}
              </router-link>
            </li>
        </template>
      </ul> -->
      <PanelMenu class="nav nav-pills flex-column mb-auto " :model="items">
        <template #item="{ item }" >
          <router-link class="nav-link" active-class="active" v-if="item.menuUrl != '/'" v-slot="{ href, navigate }" :to="item.menuUrl" custom>
            <a v-ripple class="flex align-items-center cursor-pointer text-color px-3 py-2" :href="href" @click="navigate" @mousedown="handleSubMenuClick" style="text-decoration: none;">    
                <span v-if="item.items" class="pi pi-angle-right" style="margin-right: 10px; color: #92989d;"/>            
                <span :class="item.icon" style="margin-right: 10px; color: #92989d;"/>
                <span class="ml-2 text-color">{{ item.menuName }}</span>
            </a>
          </router-link>
            <a v-else v-ripple class="flex align-items-center cursor-pointer text-color px-3 py-2" :href="item.url" :target="item.target" style="text-decoration: none;">                
                <span v-if="item.items" class="pi pi-angle-right" style="margin-right: 10px; color: #92989d;"/>
                <span :class="item.icon" style="margin-right: 10px; color: #92989d;"/>
                <span class="ml-2"><B>{{ item.menuName }}</B></span>                
            </a>
        </template>
      </PanelMenu>      
      </div>
      <!-- <div class="align-items-center">
      <hr>
        <p class="mt-5 mb-3 text-muted">
          <a href="/biz" class="mb-3 me-2 mb-md-0 text-muted text-decoration-none lh-1">
            <img class="mb-2" src="./logo.png" alt="한컴이노스트림" width="131" height="25">
          </a>
        </p>
          <div class="mb-3 mb-md-0 text-muted">Copyright &copy; Hancominnostream<br/>Since 2004. All rights Reserved.</div>
      </div> -->
    </div>
  </nav>
  </template>
      
  <script>
  import axios from 'axios'
  import { mapGetters } from "vuex"
  var requestOptions = {
    headers: {
      'Content-Type': 'application/json',
      timeout: 60000
    }
  };
  export default {
    name: 'Menu',
    data() {
      return {
        menu: [],
        isMobile : this.$isMobile(),
        items: [],      
      }
    },
    components: {
    },
    computed: {
      ...mapGetters(["isAuthenticated"]),
      ...mapGetters(["getUserEmail"]),
      // sortedMenu() {
      //   if (this.menu) {
      //     console.log(this.menu.slice().sort((a, b) => a.sort - b.sort));
      //     console.log(this.items)
          
      //     return this.menu.slice().sort((a, b) => a.sort - b.sort);        
      //   } else {
      //     return [];
      //   }
      // }
    },
    created() {
      var paramData = {};
      axios.get(`/biz/common/${this.$store.getters.getUserEmail}/${this.$store.getters.getRole}/menu`, paramData, requestOptions)
        .then((result) => {        
          result.data.mainMenu.forEach(mainMenu => {
              // 현재 메인 메뉴에 해당하는 서브 메뉴들을 필터링합니다.
              const items = result.data.subMenus.filter(subMenu => subMenu.parentMenuSeq === mainMenu.parentMenuSeq);
              // 필터링된 서브 메뉴들을 현재 메인 메뉴의 items 속성에 할당합니다.
              mainMenu.items = items;
          });
  
          this.menu = result.data.mainMenu;
          this.items = this.items = this.menu.slice().sort((a, b) => a.sort - b.sort);
        })
    },
    mounted(){
      this.onResize();
      window.addEventListener('resize',this.onResize,{passive:true});
    },
    beforeDestory(){
      if(typeof window === 'undefined') return;
      window.removeEventListener('resize',this.onResize,{passive:true});
    },
    methods: {
      topMenuButtonClick(){
        if(this.isMobile){
          document.querySelector('#topMenuButton').click();
        }
      },
      onResize(){
        this.isMobile = window.innerWidth < 600
      },
      handleSubMenuClick() {
        if (this.isMobile) {
          document.getElementById("topMenuButton").click();
        }
      },           
    },
  }
  </script>
  
  
      
  <style scoped>   
    hr{
      margin-top: 0;
    }   
    a {
      color:black;
      display:flex;      
    }    
    .p-panelmenu .p-panelmenu-header .p-panelmenu-header-content{
      border:0px solid;
    }
  
  @import './sidebars.css';
  </style>