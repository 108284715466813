<template>
	<Dialog v-model:visible="visible" maximizable :closeOnEscape=false modal header="기술지원 이력 상세" :style="{ width: modalWidth, position: 'fixed', top: '50px' }" :contentStyle="{minHeight: '35vw'}" @update:visible="$emit('closesModal'); visible = false;">				
		<Divider align="left" type="solid" style="font-size: large;">
			<b>기본정보</b>
		</Divider> 
		<!-- 본문1 -->	
		<div class="container" v-if="isMobile == false" >
			<table class="table table-bordered table-hover caption-top">
				<thead class="table-light">
					<tr class="table-primary">
						<th scope="col" v-for="item in header1" :key="item" class="center">
							{{ item }}
						</th>
					</tr>
				</thead>
				<tbody class="table-group-divider">
					<tr>						
						<td class="center"><AutoComplete v-model="company" optionLabel="companyName" dropdown :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList" style="width: 100%;"/></td>												
						<td class="center"><AutoComplete v-model="project" optionLabel="label" dropdown :suggestions="filteredProjects" @complete="projectSearch" @change="setProjectInfo"  style="width: 100%;"/></td>						
						<td class="center"><Calendar v-model="visitDate" dateFormat="yy/mm/dd" showIcon showButtonBar /></td>
						<td class="center"><Dropdown v-model="region" scrollHeight= "500px" :options="regions" optionLabel="label" placeholder="지역구분 선택" @change="changeRegion" class="w-full md:w-14rem" /></td>
						<td class="center"><Dropdown v-model="supportCategory" scrollHeight= "500px" :options="supportCategorys" optionLabel="label" placeholder="지원방법 선택" class="w-full md:w-14rem" /></td>					
					</tr>
				</tbody>
			</table>	
		</div>
		<div class="container" v-if="isMobile == true" >
			<Divider align="left" type="solid">
				<b>고객사</b>
			</Divider>
			<p><AutoComplete v-model="company" optionLabel="companyName" dropdown :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList" style="width: 100%;"/></p>
			<Divider align="left" type="solid">
				<b>프로젝트</b>
			</Divider>
			<p><AutoComplete v-model="project" optionLabel="label" dropdown :suggestions="filteredProjects" @complete="projectSearch" @change="setProjectInfo"  style="width: 100%;"/></p>
			<Divider align="left" type="solid">
				<b>방문일자</b>
			</Divider>
			<p><Calendar v-model="visitDate" dateFormat="yy/mm/dd" showIcon showButtonBar /></p>
			<Divider align="left" type="solid">
				<b>지역구분</b>
			</Divider>
			<p><Dropdown v-model="region" scrollHeight= "500px" :options="regions" optionLabel="label" placeholder="지역구분 선택" @change="changeRegion" class="w-full md:w-14rem" /></p>
			<Divider align="left" type="solid">
				<b>지원방법</b>
			</Divider>
			<p><Dropdown v-model="supportCategory" scrollHeight= "500px" :options="supportCategorys" optionLabel="label" placeholder="지원방법 선택" class="w-full md:w-14rem" /></p>
		</div>	
		<Button :icon="textareaVisible ? 'pi pi-angle-down' : 'pi pi-angle-right'" label="요청내용" severity="secondary" @click="toggleTextarea" style="margin-bottom: 0.3em;margin-left: 0.2em;"  />
		
		<Textarea v-model="requestDetails" variant="filled" rows="4" cols="70" style="width: 100%" maxlength="500" v-show="textareaVisible" />

		<br>
		<Divider align="left" type="solid" style="font-size: large;">
			<b>지원상세</b>
		</Divider> 

		<!-- 본문2 -->			
		<div class="container" v-if="isMobile == false" >
			<table class="table table-bordered table-hover caption-top">
				<thead class="table-light">
					<tr class="table-primary">
						<th scope="col" v-for="item in header2" :key="item" class="center">
							{{ item }}
						</th>
					</tr>
				</thead>
				<tbody class="table-group-divider">
					<tr>						
						<td class="center"><Dropdown v-model="supportCategoryDetail" scrollHeight= "500px" @change="changeSupportCategoryDetail" :options="supportCategoryDetails" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 120px;" /></td>						
						<td class="center"><MultiSelect scrollHeight= "500px" v-model="supportName" display="chip" :options="supportNames"  optionLabel="name" placeholder="선택" filter :maxSelectedLabels="5" class="w-full md:w-14rem" style="width: 160px;" /></td>						
						<td class="center"><Dropdown v-model="supportProd" scrollHeight= "500px" :options="supportProds" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 145px;" /></td>					
						<td class="center"><Dropdown v-model="serverType" scrollHeight= "500px" :options="serverTypes" optionLabel="label" placeholder="선택" :disabled="serverTypeDisabled" class="w-full md:w-14rem" style="width: 175px;" /></td>		
						<!-- <td class="center"><Dropdown v-model="versionCategory" scrollHeight= "500px" :options="versionCategorys" optionLabel="label" placeholder="선택" :disabled="versionCategoryDisabled" class="w-full md:w-14rem" style="width: 175px;" /></td>		
						<td class="center" style="max-width: 150px;"><input type="text" class="form-control" v-model="version" :disabled="versionDisabled" style="height:48px"/></td>		 -->
						<td class="center"><InputText type="text" v-model="versionArr[0]" :disabled="versionDisabled" style="width:100%" /></td>
						<td class="center"><InputText type="text" v-model="versionArr[1]" :disabled="versionDisabled" style="width:100%" /></td>
						<td class="center"><InputText type="text" v-model="versionArr[2]" :disabled="versionDisabled" style="width:100%" /></td>
						<td class="center"><InputText type="text" v-model="versionArr[3]" :disabled="versionDisabled" style="width:100%" /></td>
						<!-- <td class="center"><input type="text" class="form-control" v-model="supportDetailsInstallDOC" :disabled="supportDetailsInstallDocDisabled" style="height:48px" /></td>		 -->
						<td class="center d-flex">
							<!-- 메뉴형태 -->
							<!-- <label for="uploadFile" style="display:none"><input type="file" id="uploadFile" accpet="*" @change="setFileUpload"></label>
							<Button type="button" ref="menuButton" icon="pi pi-paperclip" :disabled="supportDetailsInstallDocDisabled" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" style="background-color: black;border:black;" />
							<Menu ref="menu" id="overlay_menu" :model="attachItems" :popup="true" style="white-space: nowrap;width: auto !important;"/>												 -->
							<InputText type="text" v-model="supportDetailsInstallDOC" :disabled="versionDisabled" style="width:100%" />
							<Button v-if="isValidUrl" icon="pi pi-search-plus" @click="openUrl" style="background-color: black;border:black;"  />
						</td>	
						<td class="center"><Dropdown v-model="supportStatus" scrollHeight= "500px" :options="supportStatuses" optionLabel="codeName" placeholder="선택" class="w-full md:w-14rem" /></td>							

					</tr>
				</tbody>
			</table>
		</div>	
		<div class="container" v-if="isMobile == true" >
			<Divider align="left" type="solid">
				<b>지원구분</b>
			</Divider>
			<p><Dropdown v-model="supportCategoryDetail" scrollHeight= "500px" @change="changeSupportCategoryDetail" :options="supportCategoryDetails" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 120px;" /></p>
			<Divider align="left" type="solid">
				<b>작업자</b>
			</Divider>
			<p><MultiSelect scrollHeight= "500px" v-model="supportName" display="chip" :options="supportNames"  optionLabel="name" placeholder="선택" filter :maxSelectedLabels="5" class="w-full md:w-14rem" style="width: 160px;" />			</p>
			<Divider align="left" type="solid">
				<b>제품</b>
			</Divider>
			<p><Dropdown v-model="supportProd" scrollHeight= "500px" :options="supportProds" optionLabel="label" placeholder="선택" class="w-full md:w-14rem" style="width: 145px;" /></p>
			<Divider align="left" type="solid">
				<b>서버구분</b>
			</Divider>
			<p><Dropdown v-model="serverType" scrollHeight= "500px" :options="serverTypes" optionLabel="label" placeholder="선택" :disabled="serverTypeDisabled" class="w-full md:w-14rem" style="width: 175px;" /></p>
			<Divider align="left" type="solid">
				<b>버전</b>
			</Divider>
			<p>
				<table class="table table-bordered table-hover caption-top serverVersion">
					<thead class="table-light">
						<tr class="table-primary">
							<th scope="col" v-for="item in header3" :key="item" class="center serverVersion">
								{{ item }}
							</th>
						</tr>
					</thead>
					<tbody class="table-group-divider">
						<tr class="serverVersion">	
							<td class="center serverVersion"><InputText type="text" v-model="versionArr[0]" :disabled="versionDisabled" style="width:100%" /></td>
							<td class="center serverVersion"><InputText type="text" v-model="versionArr[1]" :disabled="versionDisabled" style="width:100%" /></td>
							<td class="center serverVersion"><InputText type="text" v-model="versionArr[2]" :disabled="versionDisabled" style="width:100%" /></td>
							<td class="center serverVersion"><InputText type="text" v-model="versionArr[3]" :disabled="versionDisabled" style="width:100%" /></td>					
						</tr>
					</tbody>
				</table>
			</p>
			<Divider align="left" type="solid">
				<b>설치문서</b>
			</Divider>
			<p class = "d-flex">		
				<!-- 메뉴형태 -->		
				<!-- <label for="uploadFile" style="display:none"><input type="file" id="uploadFile" accpet="*" @change="setFileUpload"></label>
				<Button type="button" ref="menuButton" icon="pi pi-paperclip" :disabled="supportDetailsInstallDocDisabled" @click="toggle" aria-haspopup="true" aria-controls="overlay_menu" style="background-color: black;border:black;" />
				<Menu ref="menu" id="overlay_menu" :model="attachItems" :popup="true" style="white-space: nowrap;width: auto !important;"/> -->

				<InputText type="text" v-model="supportDetailsInstallDOC" :disabled="versionDisabled" style="width:100%" />
				<Button v-if="isValidUrl" icon="pi pi-search-plus" @click="openUrl" style="background-color: black;border:black;"  />
			</p>
			<Divider align="left" type="solid">
				<b>지원상태</b>
			</Divider>
			<p><Dropdown v-model="supportStatus" scrollHeight= "500px" :options="supportStatuses" optionLabel="codeName" placeholder="선택" class="w-full md:w-14rem" /></p>

		</div>    
		<Button :icon="textareaVisible2 ? 'pi pi-angle-down' : 'pi pi-angle-right'" label="지원내용" severity="secondary" @click="toggleTextarea2" style="margin-bottom: 0.3em;margin-left: 0.2em;"  />
		<Textarea v-model="responseDetail" variant="filled" rows="4" cols="70" style="width: 100%" maxlength="500" v-show="textareaVisible2" />
		<template #footer>			
			<Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />		
			<Button label="수정" icon="pi pi-check" @click="update" autofocus/>
		</template>
	</Dialog>
	
	</template>
	
	<script>
	import axios from 'axios';
	export default {
	  name: 'SupportDetailModal',
	  data(){
		return{
		  companys: [],
		  filteredCompanys: [],            
		  projects: [],
		  filteredProjects: [],             
		  company: "",
		  project: "",            
		  requestDetails: "",            
		  responseDetail: "",
		  requestDetails2: "",            
		  responseDetail2: "",
		  requestSaved: false,
		  responseSaved: false,
		  visitDate: "",    
		  region: '',  
		  regions: {},  
		  supportCategory: '',
		  supportCategorys: {},
		  supportCategorys_org: {},
		  serverTypes: {},
		  serverType: '',
		  supportCategoryDetails: {},	
		  supportCategoryDetail: '',  
		  versionCategorys: {},
		  versionCategory: '',
		  supportDetailsInstallDOC: '',		  
		  supportDetailsInstallDOCuuid: '',	
		  details: {},
		  version: '',
		  versionArr: [ "", "", "", ""],
		  versionCategoryArr: [ "", "", "", ""],
		  visible: true,
		  supportProds: {},	
		  supportProd: '',
		  supportStatuses: {},	
		  supportStatus: '',
		  modalWidth: this.isMobile ? '100vw' : '90%',		  
		  supportName: '',		  
		  header1: ["고객사", "프로젝트", "지원일자", "지역구분", "지원방법"],		  
		//   header2: ["지원구분", "작업자", "제품", "서버구분", "버전구분", "버전", "설치문서", "지원상태"],
		  header2: ["지원구분", "작업자", "제품", "서버구분", "서버모듈(ver)", "뷰어(ver)", "디자이너(ver)", "모듈(ver)", "설치문서", "지원상태"],
		  header3: ["서버모듈(ver)", "뷰어(ver)", "디자이너(ver)", "모듈(ver)"],
		  requestDetailsVisible: false,
		  supportDetailVisible: false,
		  serverTypeDisabled: false,
		  versionCategoryDisabled: false,
		  versionDisabled: false,
		  supportDetailsInstallDocDisabled: false,
		  textareaVisible: false, 
		  textareaVisible2: false,	
		  attachItems: [
		  		{
                    label: 'test',               
                    visible: false,					
					disabled: true,
                },                
				{
                    label: '재업로드',
                    icon: 'pi pi-upload',
                    command: (event) => {                        
                        document.getElementById('uploadFile').click();
                    },
                },
                {
                    label: '다운로드',
                    icon: 'pi pi-download',
                    command: (event) => {                        
						this.downloadFile();
                    },
                    visible: false,
                    //disabled:true,
                },                
            ],
            isMenuOpen:true,
			isValidUrl:false,
		}
	  },
	  props: {
		isMobile: Boolean,
		supportDetailsKey: Number,
		supportNames: Array,
		isAdmin: String,
	  },
	  components: {
	  },
	  created(){
	  },
	  mounted() {
		this.$nextTick(function() {
			this.setSupportInfo();
		});
		//달력(속성 변경)
		this.$calendar();			
	  },
	  watch: {					
		supportCategoryDetail() {			
			this.changeSupportCategoryDetail();	
		}
	  },
	  methods: {		
		setSupportInfo() {			
			this.$get(`/biz/support/get`, {
							params: {
								supportDetailsKey : this.supportDetailsKey,
							}
						},
							function(response, i){								
								var detail = response.data.detail;								

								i.details = detail;
								i.company = detail.companyName;
								i.project = detail.projectName;
								i.requestDetails = detail.requestDetails;
								i.visitDate = new Date(detail.visitDate);								
								i.version = detail.version;
								i.supportDetailsInstallDOC = detail.supportDetailsInstallDOC;
								i.supportDetailsInstallDOCuuid = detail.supportDetailsInstallDOCuuid;
								i.responseDetail = detail.responseDetails;
								i.versionCategoryArr = detail.versionCategory.split(",");
								
								var versionSplited = i.version.split(",");
								for(var k = 0; k< i.versionCategoryArr.length; k++){
									if(i.versionCategoryArr[k] == "서버엔진") {
										i.versionArr[0] = versionSplited[k];
									} else if(i.versionCategoryArr[k] == "뷰어") {
										i.versionArr[1] = versionSplited[k];
									} else if(i.versionCategoryArr[k] == "디자이너") {
										i.versionArr[2] = versionSplited[k];
									} else if(i.versionCategoryArr[k] == "모듈") {
										i.versionArr[3] = versionSplited[k];
									}									
								}

								// [0] : 파일명 / [1] : 업로드 / [2] : 다운로드																
								if(i.supportDetailsInstallDOC != null && i.supportDetailsInstallDOC != ""){	
									i.attachItems[0].label = i.supportDetailsInstallDOC.match(/[^\\/]*$/)[0];
									i.attachItems[0].visible = true;													
									i.attachItems[2].visible = true;
								} else {									
									i.attachItems[1].label = "업로드";
									i.attachItems[0].visible = false;
									i.attachItems[2].visible = false;				
								}

								i.$code(["SERVER_TYPE", "REGION", "SUPPORT_CATEGORY", "SUPPORT_CATEGORY_DETAIL", "VERSION_CATEGORY", "PROD_CODE", "SUPPORT_STATUS"], false,
									function(result, i){       	    
										i.regions = result.region;
										i.supportCategorys = result.supportCategory;   
										i.supportCategorys_org = i.supportCategorys;   
										i.supportCategoryDetails = result.supportCategoryDetail;
										i.serverTypes = result.serverType;
										i.supportProds = result.prodCode;
										i.versionCategorys = result.versionCategory;
										i.supportStatuses = result.supportStatus;
																				
										i.regions.forEach(function(region, index) {										
											if(detail.region === region.codeName){
												i.region = region;												
											}
										});		

										i.supportCategorys.forEach(function(supportCategory, index) {										
											if(detail.supportCategory === supportCategory.codeName){
												i.supportCategory = supportCategory;												
											}
										});										

										i.supportCategoryDetails.forEach(function(supportCategoryDetail, index) {										
											if(detail.supportCategoryDetail === supportCategoryDetail.codeName){
												i.supportCategoryDetail = supportCategoryDetail;												
											}
										});
										
										var supportNamesArr = [];
										i.supportNames.forEach(function(supportName, index) {											
											detail.supportNames.split(",").forEach(function(sNames, index) {
												if(sNames === supportName.name){													
													supportNamesArr.push(supportName);																									
												}
											});
											i.supportName = supportNamesArr;
										});


										i.supportProds.forEach(function(supportProd, index) {										
											if(detail.supportProd === supportProd.codeName){
												i.supportProd = supportProd;												
											}
										});
										
										i.versionCategorys.forEach(function(versionCategory, index) {										
											if(detail.versionCategory === versionCategory.codeName){
												i.versionCategory = versionCategory;												
											}
										});										

										i.supportStatuses.forEach(function(supportStatus, index) {										
											if(detail.supportStatus === supportStatus.codeName){
												i.supportStatus = supportStatus;												
											}
										});										

										i.serverTypes.forEach(function(serverType, index) {
											if(detail.serverType === serverType.label){
												i.serverType = serverType;
											}
										});
									},
									function(e, i){
										console.log(e);
									}
								);
								i.$get('/biz/company/list',  {
											params: {}
									},
									function(response, i){		

											i.companys = response.data.result;   //기존데이터에 추가
											i.companys.forEach(function(company, index) {
											if(detail.companySeq === company.companySeq){
												i.company = company;
											}
										});
									},
									function(e, i){
										console.log(e);
									}
								);
								i.$get('/biz/project/list',  {
											params: {
												companySeq : detail.companySeq,
										}
									},
									function(response, i){																				
										i.projects = response.data.result;   //기존데이터에 추가
										i.projects.forEach(function(project, index) {
											if(detail.companySeq === project.companySeq && detail.projectUid === project.projectUid){
												i.project = project;
											}
										});
									},
									function(e, i){
										console.log(e);
									}
								);		
								
								i.validateUrl();
						} 
					);						
		},			
		setProjectList() {			
			this.partnerName = "";
			this.deliveryProdName = "";
			this.salesRepresentativeName = "";
			this.project = "";
			if(this.company.companySeq != undefined){
				var param = {
					companySeq : this.company.companySeq,
				};
			
				this.$get('/biz/project/list',  {
								params: param
					 },
						function(response, i){
							i.projects = response.data.result;   //기존데이터에 추가
						},
						function(e, i){
							console.log(e);
						}
				);
			}
				
		},
		companySearch(event) {					
				 setTimeout(() => {
					if (!event.query.trim().length) {
						this.filteredCompanys = [...this.companys];
					} else {
						this.filteredCompanys = this.companys.filter((company) => {
							return company.companyName.toLowerCase().includes(event.query.toLowerCase());
						});
					}
				}, 250);
		},
		projectSearch(event) {
			setTimeout(() => {
				if (!event.query.trim().length) {
					this.filteredProjects = [...this.projects];					
				} else {
					this.filteredProjects = this.projects.filter((project) => {
						return project.projectName.toLowerCase().includes(event.query.toLowerCase());
					});					
				}
			}, 250);            
		},
		setProjectInfo(){
			const current = new Date();
			
			if(this.project.companySeq != undefined && this.project.projectUid != undefined ){
				var param = {
					companySeq : this.project.companySeq,
					projectUid : this.project.projectUid
				};
			  
				  this.$get(`/biz/project/get`, {
								params: param
								},
								function(response, i){
									
									i.partnerName = response.data.detail.partnerName;
									i.deliveryProdName = response.data.detail.deliveryProdName;
									i.salesRepresentativeName = response.data.detail.salesRepresentativeName;
									i.partnerYn = response.data.detail.partnerYn;
									i.year = response.data.detail.year !== "" ? response.data.detail.year : current.getFullYear();
									
								},
								function(e, i){
									console.log(e);
								}
				 );
			 }
		
		},    
		changeSupportCategoryDetail(){			
			if(this.supportCategoryDetail.label == "교육" || this.supportCategoryDetail.label == "미팅"){
				this.serverTypeDisabled = true;			
				this.versionCategoryDisabled = true;			
				this.versionDisabled = true;			
				this.supportDetailsInstallDocDisabled = true;			
				this.serverType = "";
				// this.versionCategory = "";
				// this.version = "";
				this.versionArr = [ "", "", "", "" ];
				this.supportDetailsInstallDOC = "";
			} else if(this.supportCategoryDetail.label == "기술지원" || this.supportCategoryDetail.label == "기능패치" || this.supportCategoryDetail.label == "버그패치"){
				this.serverTypeDisabled = false;
				this.versionCategoryDisabled = false;
				this.versionDisabled = false;
				this.supportDetailsInstallDocDisabled = true;
				this.supportDetailsInstallDOC = "";
			} else {
				this.serverTypeDisabled = false;
				this.versionCategoryDisabled = false;
				this.versionDisabled = false;
				this.supportDetailsInstallDocDisabled = false;
			}
		},		
		changeRegion(event){
			if(event.value.codeName == "본사"){				
				this.supportCategorys = this.supportCategorys_org.filter(item => item.codeSort > 1);
			} else {				
				this.supportCategorys = this.supportCategorys_org.filter(item => item.codeSort > 0);
			}
		},
		async update(){
			var checked = false;
			var supportNames = this.supportName;
			var supportNames2 = "";
			var self = this;
			for(var i=0; i<supportNames.length; i++){				
				supportNames2 += supportNames[i].name +",";								
			}
			supportNames2 = supportNames2.slice(0, -1)

			var versionArr = "";
			var versionCategorys = "";

			if(this.versionArr[0] != "") {
				versionArr += this.versionArr[0] + ",";
				versionCategorys += "서버엔진" + ",";
			} 
			if(this.versionArr[1] != "") {
				versionArr += this.versionArr[1] + ",";
				versionCategorys += "뷰어" + ",";
			}
			if(this.versionArr[2] != "") {
				versionArr += this.versionArr[2] + ",";
				versionCategorys += "디자이너" + ",";
			}
			if(this.versionArr[3] != "") {
				versionArr += this.versionArr[3] + ",";
				versionCategorys += "모듈" + ",";
			}			

			if(this.details.supportNames.indexOf(this.$store.getters.getName) == "-1" && this.isAdmin != 'Y') {				
				this.$messageShow('권한이 없습니다.(작업자만 가능)', 'warn');
			} else if (this.company == ""){
				this.$messageShow('고객사를 입력하세요.', 'warn');
			} else if (this.project == ""){
				this.$messageShow('프로젝트를 선택하세요.', 'warn');
			} else if (this.visitDate == null){
				this.$messageShow('날짜를 선택하세요.', 'warn');
			} else if (this.company == this.details.companyName &&
				this.project  == this.details.projectName &&
				this.$simpleDateTimeFormat(this.visitDate, "yyyy/MM/dd")  == this.details.visitDate &&
				this.region.label  == this.details.region &&
				this.supportCategory.label  == this.details.supportCategory &&
				this.requestDetails  == this.details.requestDetails &&
				this.supportCategoryDetail.label  == this.details.supportCategoryDetail &&
				supportNames2  == this.details.supportNames &&
				this.supportProd.label  == this.details.supportProd &&
				this.serverType.label  == this.details.serverType &&
				// this.versionCategory.label  == this.details.versionCategory &&
				// this.version  == this.details.version &&
				versionCategorys.slice(0, -1) == this.details.versionCategory &&
				versionArr.slice(0, -1) == this.details.version &&				
				((this.supportDetailsInstallDOC  == this.details.supportDetailsInstallDOC) ||  (this.supportDetailsInstallDOC == "" && this.details.supportDetailsInstallDOC == null)) &&
				this.supportStatus.label  == this.details.supportStatus &&
				this.responseDetail  == this.details.responseDetails) {
					this.$messageShow('기존 정보와 같습니다.', 'warn');
			} else {
				checked = true;
			}
			if(checked && this.validationCheck()) {				
				if(typeof this.supportDetailsInstallDOC == "object" && this.supportDetailsInstallDOC != null){
					//설치문서 업로드
					//await this.uploadFiles();
				}				
				var param = {	          
					"supportDetailsKey" : this.supportDetailsKey
					,"projectUid" : this.projects[0].projectUid
					,"supportCategory" : this.supportCategory.label
					,"requestDetails" : this.requestDetails
					,"region" : this.region.label
					,"registerName" : this.$store.getters.getName
					,"visitDate" : this.$simpleDateTimeFormat(this.visitDate, "yyyy/MM/dd")
					,"supportCategoryDetail" : this.supportCategoryDetail.label
					,"serverType" : this.serverType.label
					,"supportDetail" : this.responseDetail
					,"supportStatus" : this.supportStatus.codeName
					,"supportDetailsInstallDOC" : this.supportDetailsInstallDOC					
					,"supportDetailsInstallDOCuuid" : this.supportDetailsInstallDOCuuid
					,"supportNames" : supportNames2
					,"supportProd" : this.supportProd.label
					,"versionCategory" : versionCategorys.slice(0, -1)
					,"version" : versionArr.slice(0, -1)				
					};

					 self.$post(`/biz/support/mod`, param,
						function(response, i){
							self.$messageShow('수정되었습니다.', 'success');     
							i.visible = false;
							i.$emit('closesModal');
							i.$emit('update');            
						},
						function(e, i){
							console.log(e);
						}
				);	
			}
			
		},	
		validationCheck(){					
			var scd_Label = this.supportCategoryDetail.label;
			if(this.supportCategoryDetail == "") {
				this.$messageShow('지원구분을 선택해주세요.', 'warn');
			} else if(this.supportName == "") {
				this.$messageShow('작업자를 선택해주세요.', 'warn');
			} else if(this.supportProd == "") {
				this.$messageShow('제품을 선택해주세요.', 'warn');
			} else if(this.serverType == "" && (scd_Label != "교육" && scd_Label != "미팅")) { // 교육, 미팅
				this.$messageShow('서버구분을 선택해주세요.', 'warn');
			} else if(this.supportDetail == "") {
				this.$messageShow('지원내용을 입력해주세요.', 'warn');
			} else if(this.supportStatus == "") { 
				this.$messageShow('지원상태를 선택해주세요.', 'warn');
			} else {
				return true;
			}
		},
		onReqCloseDialog(){
			if(!this.requestSaved) {
				this.requestDetails = this.requestDetails2;				
			}
		},
		onResCloseDialog(){			
			if(!this.responseSaved) {
				this.responseDetail = this.responseDetail2;				
			}
		},
		onSaveRequest(){
			this.requestDetailsVisible = false;			
			this.requestSaved = true;
		},
		onSaveResponse(){
			this.supportDetailVisible = false;
			this.responseSaved = true;
		},
		toggleTextarea() {						
			this.textareaVisible = !this.textareaVisible;
    	},
		toggleTextarea2() {							
			this.textareaVisible2 = !this.textareaVisible2;					
    	},toggle(event) {           
			this.$refs.menu.toggle(event);
        },
		async uploadFiles() {			
			try {				
				if (this.supportDetailsInstallDOC !== "") {					
					const response = await axios.post('/biz/common/fileUpload', this.supportDetailsInstallDOC, {
						headers: {
							'Content-type': 'multipart/form-data'
						}
					});					
					this.$messageShow(response.data.split('\n')[0], 'warn');						
					this.supportDetailsInstallDOC = response.data.split('\n')[1];
					this.supportDetailsInstallDOCuuid = response.data.split('\n')[2];
				}				
			} catch (error) {				
				console.log(error.response.data.errorMsg)
				this.$messageShow(error.response.data.errorMsg, 'error');
			}
		},
		setFileUpload() {  			
			var frm = new FormData();
			var file_doc = document.getElementById("uploadFile");
			var file = file_doc.files[0];			
			
			if(file!=null && file!=undefined){
				if (file.size > 10485760) {				
					this.$messageShow("업로드 용량 제한 : 10mb", 'error');
				} else {					
					frm.append("uploadFile", file);
					frm.append('uuidFilename', this.supportDetailsInstallDOCuuid);
					frm.append('uploadType','installDOC');
					this.supportDetailsInstallDOC = frm;
				}
			}
		},		
		downloadFile() {
            var params = {
                supportDetailsKey : this.supportDetailsKey,
				downloadType : "installDOC"
            }
			axios.post('/biz/common/download', params, {
                responseType: 'arraybuffer' // 바이너리 데이터로 응답 받기 위해 responseType을 'arraybuffer'으로 설정
            }).then(response => {
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);

                // 서버에서 받은 파일명 가져오기
                const contentDisposition = response.headers['content-disposition'];
                const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                const matches = filenameRegex.exec(contentDisposition);
				const filename = matches != null && matches[1] ? decodeURIComponent(matches[1].replace(/['"]/g, '')) : 'download_error'; // 서버에서 파일명이 없을 경우 기본값으로 'downloaded_file.png' 설정

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename); // 다운로드될 파일 이름 설정
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                console.error('파일 다운로드 중 오류 발생:', error);
            });
        }, 
		validateUrl() {
			const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
			this.isValidUrl = urlPattern.test(this.supportDetailsInstallDOC);			
		}, 
		openUrl() {
			window.open(this.supportDetailsInstallDOC, '_blank');
		},
	  },
	}
	</script>
	
	<style lang="css" scoped>
	.container {
	  display: flex;
	  margin-top: 20px;
	  margin-bottom: 10px;
	  margin-left: 0px;
	  justify-content: center
	}
	.container > * {
	  margin-right: 10px;
	  font-size: 15px;
	}
	.container > *:last-child {
	  margin-right: 0;
	}
	.container .selects {
	  display: flex;
	}
	.container .selects select {
	  width: 120px;
	  margin-right: 10px;
	}
	.container .selects select:last-child {
	  margin-right: 0;
	}
	
	.center {
	  text-align: center;
	  min-width: 90px;
	}
	.container .btn {
	  width: 120px;
	  height: 50px;
	  font-weight: 700;
	  flex-shrink: 0;
	}
	@media only screen and (max-width: 480px) {
		.container {
		  display: block;
		}
		.selects {
		  margin-top: 10px;
		}
		.btn {
		  margin-top: 10px;
		}
	}
	
	th{
	  text-align: center;
	}
	
	.pi{
	  min-width: 56px;
	  width: 56px;
	  min-height: 50px;
	  height: 50px;
	}
	.serverVersion {
		font-size:small;
		min-width: 80px;
	}
</style>