<template>
	<Dialog v-model:visible="visible" modal header="비밀번호 수정" :closable='false' :closeOnEscape="defaultPwYn!='true'" :style="{ width: modalWidth, minWidth: '300px' }">
	  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
			<Divider align="left" type="solid">
		    	<b>신규 패스워드</b>
			</Divider>
			<p>
				<Password v-model="newPassword1" :feedback="false" toggleMask />
			</p>
			<Divider align="left" type="solid">
		    	<b>신규 패스워드 재입력</b>
			</Divider>
			<p>
				<Password v-model="newPassword2" :feedback="false" toggleMask />
			</p>
		</div>
       <div class="flex flex-wrap justify-content-left gap-2 mb-2">
      	<b style="color:red;font-size:14px;">{{errorMessage}}</b>
      </div>
      <template #footer>
		 <Button label="취소" icon="pi pi-times" @click="cancel" text />
		 <Button label="저장" icon="pi pi-check" @click="updateUserPassword" autofocus />
     </template>
 	</Dialog>
</template>

<script>
import axios from 'axios'
export default {
  name: 'Modal',
  data(){
    return{
    	newPassword1: "",
    	newPassword2 : "",
    	errorMessage : "",
    	visible: true,
    	modalWidth: this.$isMobile() ? '100vw' : '15vw'
    }
  },
  created(){
  },
  	props: {
		defaultPwYn: Boolean,
	},	
	methods: {
		updateUserPassword() {
		
			if(confirm("비밀번호를 변경하시겠습니까?")){
			
				this.errorMessage = '';
				const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{10,}$/;
				if(this.newPassword1 == "" || this.newPassword2 == "") {
					this.errorMessage = '변경하실 비빌번호를 입력하세요.';
				}else{	        
					if(this.newPassword1 != this.newPassword2){
						this.errorMessage = '변경하실 비밀번호가 일치하지 않습니다.';
					} else if(this.newPassword1 == "qwer1234!" || this.newPassword2 == "qwer1234!") {
						this.errorMessage = '기본 비밀번호는 변경이 필요합니다.(다른 비밀번호를 사용 해주세요.)';
					} else if (!reg.test(this.newPassword1) || !reg.test(this.newPassword2)) {
						this.errorMessage = '최소 10 자 이상, 하나 이상의 대문자, 하나의 숫자 ,하나의 특수 문자를 포함 시켜주세요.';
					} else{				
						var paramData = {
							"userEmail": this.$store.getters.getUserEmail,
							"firstLoginYn": "Y",
							"password" : this.newPassword1,
							"reset": false
						};
						
						this.$post('/biz/account/mod/update-pw', paramData,
									function(response, i){
									alert("비밀번호가 변경되었습니다.");
									i.visible = false;
									i.$emit('closesModal');
									i.$router.replace('/biz');	
									},
									function(e, i){
									console.log(e);
									i.errorMessage = e;
									}
						);
					}
					
				}
				
			}
		},
		cancel(){			
			if(this.defaultPwYn){
				if(confirm("기본 비밀번호를 변경해주세요. 취소 시 로그아웃 됩니다.")){
					return;
				}
				else {
					this.$logout();
				}
			} else {				
				this.$emit('closesModal');
			}			
			
		}
	},

}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>

