<template>
<Dialog v-model:visible="visible" modal header="발급 일련번호 변경" style="{ width: 50vw }" @update:visible="$emit('closesModal'); visible = false;">
	  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
      	<Divider align="left" type="solid">
	    	<b>발급 일련번호 입력</b>
		</Divider>
		<p>
		   <input type="text" class="form-control" v-model="projectSerialNo" />
		</p>
		
		</div>
      <template #footer>
        <Button label="닫기" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
        <Button label="저장" v-if="engineerRoleCheck == true" icon="pi pi-check" autofocus @click="update"/>
    </template>
  </Dialog>
</template>

<script>

export default {
  name: 'ProjectSerialNoUpdate',
  data(){
    return{
      visible: true,    
      projectUid:"",
      companySeq:"",
      projectName:"",
      projectSerialNo: "",
      year:"",
      salesRepresentativeEmail:"",
      partnerYn:"",
      partnerName : "",  
      project_mngSeq:"",
      registerEmail:"",
      modifierEmail:"",
      useYn:"",

    }
  },
  props: {
  	detailsCompanySeq: Number,
    detailsProject: Object,
    engineerRoleCheck: Boolean,
  },
  components: {
  },
  created(){
    this.projectSerialNo = this.detailsProject.projectSerialNo;
  },
  mounted(){
  		
  },
  methods: {
     update() {
    	var checked = false;
    	if(this.projectSerialNo != ''){
			checked = true;
	    }else{
	    	this.$messageShow('라이선스 일련번호 입력이 필요합니다.', 'warn');
	    	return checked;
	    }
    	if(checked){
    		if(confirm("라이선스 일련번호를 변경하시겠습니까?")){          
    			var param = {            
              "projectUid": this.detailsProject.projectUid,
              "companySeq": this.detailsProject.companySeq,
              "projectName": this.detailsProject.projectName,
              "projectSerialNo" : this.projectSerialNo,
              "year": this.detailsProject.year,
              "salesRepresentativeEmail": this.detailsProject.salesRepresentativeEmail,
              "partnerYn": this.detailsProject.partnerYn,
              "partnerName" : this.detailsProject.partnerName,
              "project_mngSeq": this.detailsProject.project_mngSeq,
              "registerEmail": this.detailsProject.registerEmail,
              "modifierEmail": this.$store.getters.getUserEmail,
              "useYn": this.detailsProject.useYn,
		        };
		        
		        this.$post('/biz/project/mod/se-no', param,
			                function(response, i){
			                	i.$messageShow('라이선스 일련번호가 변경되었습니다.', 'success');
			                 	i.visible = false;
			                	i.$emit('closesModal');
		          				  i.$emit('update');
			                },
			                function(e, i){
			                	i.errorMessage = e;
			                }
			 	);  
    		}
    	
    	}
    
    },
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>

