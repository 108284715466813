
<template>    
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
      <h1 class="h2">리포트 유틸
      </h1>
    </div>    
    <Chip v-if="isMobile == false" label="여러 파일은 zip으로 압축 필요!" icon="pi pi-megaphone" style="margin-left: 15px;" />
    <div class="container" v-if="isMobile == false">            
      <input type="file" ref="fileInput" id="uploadFile" accept=".zip, .reb, .crf" @click="clickFileUpload" @change="setFileUpload" style="height: 50px">
      <Button label="업로드" ref="btnUpload" @click="uploadFiles" :disabled="btnUploadDisabled"></Button>      
    </div>
    <div v-if="isUpload == true">    
      <div class="container" v-if="isMobile == false">
        <Dropdown v-model="reportUtilType" filter scroll-height="250px" :options="reportUtilTypes" optionLabel="name" placeholder="기능 선택" class="w-full md:w-14rem" @change="changeDropdown" :disabled="reportUtilTypeDisable" style="width:300px;" />
        <div v-for="category in ranges" :key="category.code" class="flex align-items-center">
          <div v-if="rbDisplay" style="width:99px">
            <RadioButton v-model="range" :inputId="category.code" name="dynamic" :value="category.code" @change="changeRadio" />
            <label :for="category.code" class="ml-2">{{ category.name }}</label>
          </div>
        </div>      
          <!-- 컨트롤(찾기) --><Dropdown v-if="isGetPropertyC" v-model="getPropertyCnm" scroll-height="250px" :options="Object.keys(getPropertyData)" optionLabel="" placeholder="컨트롤 선택"  @change="changePropCDropdown" style="width:120px" />  
          <!-- 속성(찾기) --><Dropdown v-if="isGetPropertyP" v-model="getPropertyPnm" scroll-height="250px" :options="Object.keys(getPropertyData[getPropertyCnm])" optionLabel="" placeholder="속성 선택" @change="changePropPDropdown" style="width:150px" />                
          <!-- 속성 값(찾기) --><Dropdown v-if="isGetPropertyPV" v-model="getPropertyPVnm" scroll-height="250px" :options="getPropertyValues" optionLabel="label" placeholder="속성 값 선택" @change="changePropPVDropdown"  />          
          <ColorPicker v-if="isColorPicker" v-model="color" :format=getPropertyPVnm.code @change="changeColorPicker"  />        
          <input v-model="inputPropVal" class="form-control" type="text" :placeholder="inputPropValPlaceholder" @blur="validate('find',inputPropVal)" :style="{'width':'auto', 'display': inputPropValDisplay}"/>
        <input v-model="reportUtilKeyword" class="form-control" type="text" placeholder="찾을 키워드" @keyup.enter="findReportUtil" :style="{'width':'auto', 'display': reportUtilKeywordDisplay}"/>
        
        <Button label="찾기" @click="findReportUtil" :disabled="findBtnDisable" style="background-color: green;width:90px"></Button>
        <Button label="다운(찾기)" @click="downloadFile('reportUtil_Find')" :style="{'background-color': 'black','display': downloadBtnDisplay}"></Button>
      </div>
      
      <div class="container" v-if="changeDiv && isMobile == false" style="margin-left:315px;">            
        <!-- 속성 값(바꾸기) --><Dropdown v-if="isGetPropertyPV2" v-model="getPropertyPVnm2" scroll-height="250px" :options="getPropertyValues" optionLabel="label" placeholder="속성 값 선택" @change="changePropPV2Dropdown" style="margin-left:285px;" />  
        <ColorPicker v-if="isColorPicker2" v-model="color2" :format=getPropertyPVnm2.code @change="changeColorPicker2" />
        <input v-model="inputPropVal2" class="form-control" type="text" :placeholder="inputPropValPlaceholder" @blur="validate('change',inputPropVal2)" :style="{'width':'auto', 'display': inputPropVal2Display, 'margin-left': isGetPropertyPV2 === false ? '265px' : ''}"/>
        <input v-model="reportUtilChangeKeyword" class="form-control" type="text" placeholder="바꿀 키워드" @keyup.enter="changeReportUtil" :style="{'width':'auto', 'display': reportUtilKeyword2Display}"/>
        
        <Button label="바꾸기" @click="changeReportUtil" :disabled="changeBtnDisable" style="background-color: tomato;width:90px"></Button>
        <Button label="다운(변환)" @click="downloadFile('reportUtil_Change')" :style="{'background-color': 'black','display': downloadBtn2Display}"></Button>
      </div>
      <div class="container" v-if="isMobile == false">
        <Textarea v-model="resultText" spellcheck="false" variant="filled" rows="10" cols="70" readonly style="width: 100%; height:500px;" />	
      </div>
  </div>
    <div class="container" v-if="isMobile == false">
        <LoadingBar :loading= "loading" v-if="loading == true"></LoadingBar>
    </div>
    <div v-if="isMobile == true">
      <Chip label="모바일로 보기 좁아요. PC로 보세요." icon="pi pi-megaphone" style="margin-left: 15px;" />
      <a href="/biz" >
        <Button icon="pi pi-home" severity="secondary" style="width:2rem;height:2rem;"  />
      </a>
    </div>
  </template>
  
  <script>
  import LoadingBar from '@/components/Common/LoadingBar.vue'
  import axios from 'axios';
  export default {
    name: 'ReportUtil',
    data() {
      const g선모양 = [
        { label: "선없음", code: "None" },
        { label: "실선", code: "Solid" },
        { label: "쇄선", code: "Dash" },
        { label: "점선", code: "Dot" },
        { label: "일점쇄선", code: "DashDot" },
        { label: "이점쇄선", code: "DashDotDot" }
      ];

      const g선두께 = [
        { label: "0.25", code: "W025" },
        { label: "0.5", code: "W050" },
        { label: "0.75", code: "W075" },
        { label: "1.0", code: "W100" },
        { label: "1.5", code: "W150" },
        { label: "2.25", code: "W225" },
        { label: "3.0", code: "W300" },
        { label: "4.5", code: "W450" },
        { label: "6.0", code: "W600" }
      ];

      const g선색 = [
        { label: "색상 직접 선택", code: "rgb" },
        { label: "RGB 입력", code: "rgb" },
        { label: "HEX 입력", code: "hex" },
      ]
      
      const g배경투명 = [
        { label: "보통", code: "Normal" },
        { label: "배경투명", code: "Transparent" },
      ]      
      return {    
        isUpload: false,
        loading: false,
        frmUpload: null,        
        frmUploadUuid : '',
        btnUploadDisabled : true,
        reportUtilType : '',
        reportUtilTypeDisable : true,
        reportUtilTypes : [
                    {
                      //getTableCell_FontList, [표,셀,라벨의 모든 폰트 검색-컨트롤 단위],  SearchWord로 검색 가능(완전일치), [서식별]
                      name: '[폰트] 표,셀,라벨에서 찾기,바꾸기(결과 : 컨트롤 단위)',
                      code: 'getTableCell_FontList',                      
                      range: 'each'
                    },
                    {
                      //getTableCell_FontList_OnlyFont, [표,셀,라벨의 모든 폰트 검색-폰트명만],  SearchWord로 검색 가능(완전일치), [전체, 서식별]
                      name: '[폰트] 표,셀,라벨에서 찾기(결과 : 폰트명만)',
                      code: 'getTableCell_FontList_OnlyFont',                      
                      rbDisplay: true,
                      isChange: false,
                    },
                    {
                      //getFormula, [공식필드 문자열 검색-위치만],  SearchWord로 검색 가능(like), [서식별]
                      name: '[공식필드] 문자열 찾기,바꾸기(결과 : 위치만)',
                      code: 'getFormula',                      
                      range: 'each',
                      isInputKeyword: true,
                    },
                    {
                      //getFormula_Detail, [공식필드 문자열 검색-내용포함],  SearchWord로 검색 가능(like), [서식별]
                      name: '[공식필드] 문자열 찾기(결과 : 내용포함)',
                      code: 'getFormula_Detail',                      
                      range: 'each',
                      isInputKeyword: true,
                      isChange: false,
                    },
                    {
                      //getFormula_25Style, [공식필드] 2.5 스타일 찾기,바꾸기(결과 : 위치만), SearchWord로 검색 X, [서식별]
                      name: '[공식필드] 2.5 스타일 찾기,바꾸기(결과 : 위치만)',
                      code: 'getFormula_25Style',                      
                      range: 'each',
                      isInputKeyword: false,
                      isInputKeyword2: false,                      
                    },
                    {
                      //getFormula_VBScript, [공식필드] VB Script 찾기(결과 : 위치만), SearchWord로 검색 X, [서식별]

                      name: '[공식필드] VB Script 찾기(결과 : 위치만)',
                      code: 'getFormula_VBScript',
                      range: 'each',                      
                      isInputKeyword: false,
                      isInputKeyword2: false,
                      isChange: false,                      
                    },
                    {
                      //getConnection_Query, [쿼리 문자열 검색-데이터셋명만], SearchWord로 검색 가능(like), [서식별]
                      name: '[쿼리] 문자열 찾기, 바꾸기(결과 : 데이터셋명만)',
                      code: 'getConnection_Query',
                      range: 'each',
                      isInputKeyword: true,
                    },
                    {
                      //getConnection_Query_Detail, [쿼리 문자열 검색-쿼리문포함], SearchWord로 검색 가능(like), [서식별]
                      name: '[쿼리] 문자열 찾기(결과 : 쿼리문 포함)',
                      code: 'getConnection_Query_Detail',
                      range: 'each',
                      isInputKeyword: true,
                      isChange: false,
                    },                    
                    {
                      //getTableCell_Text, [표,셀,라벨의 문자열 찾기,바꾸기-위치만], SearchWord로 검색 필수(like), [서식별]
                      name: '[문자열] 표,셀,라벨 에서 문자열 찾기,바꾸기(결과 : 위치만)',
                      code: 'getTableCell_Text',
                      range: 'each',
                      isInputKeyword: true,
                    },                    
                    {
                      //getTableCell_TextAndFormula, [표,셀,라벨.공식필드의 문자열 검색-위치만], SearchWord로 검색 필수(like), [서식별]
                      name: '[문자열] 표,셀,라벨,공식필드 에서 문자열 찾기,바꾸기(결과 : 위치만)',
                      code: 'getTableCell_TextAndFormula',
                      range: 'each',
                      isInputKeyword: true,
                    },                    
                    {
                      //getTableCell_OutputFormat, [표,셀,라벨의 출력양식 검색-위치만], SearchWord로 검색 필수(완전일치), [서식별]
                      name: '[출력양식] 표,셀,라벨에서 문자열 찾기,바꾸기(결과 : 위치만)',
                      code: 'getTableCell_OutputFormat',
                      range: 'each',                      
                    },
                    {
                      //getTableCell_Image, [표,셀,라벨의 모든 이미지 검색], SearchWord로 검색 가능(선택), [서식별]
                      name: '[이미지] 표,셀,라벨에서 찾기(결과 : 위치만)',
                      code: 'getTableCell_Image',
                      range: 'each',                      
                      isChange: false,
                    },
                    {
                      //getConnection_XML_RepeatNode, [XML DS] 반복노드 찾기,바꾸기(결과 : XML관련정보), SearchWord로 검색 가능(선택), [서식별]
                      name: '[XML DS] 반복노드 찾기,바꾸기(결과 : XML관련정보)',
                      code: 'getConnection_XML_RepeatNode',
                      range: 'each',                      
                    },
                    {
                      //getConnection_XML_Path, [XML DS] Path 찾기,바꾸기(결과 : XML관련정보), SearchWord로 검색 가능(선택), [서식별]
                      name: '[XML DS] Path 찾기,바꾸기(결과 : XML관련정보)',
                      code: 'getConnection_XML_Path',
                      range: 'each',
                      isInputKeyword: true,                      
                    },
                    {
                      //getProperty, 속성 찾기,바꾸기, SearchWord로 검색 필수(like), [서식별]
                      name: '[종합] 표,표셀,라벨 속성 찾기,바꾸기',
                      code: 'getProperty',
                      range: 'each',
                      isInputKeyword: false,
                      isInputKeyword2: false,
                      isInputPropDropKeyword: true,
                      isInputPropDropKeyword2: true,
                    }
        ],
        range: 'total',
        ranges : [
            {
              name: '전체',
              code: 'total',
            },
            {
              name: '서식별',
              code: 'each',
            },
        ],
        resultText: '',
        reportUtilKeywordDisplay : 'None',  
        reportUtilKeyword2Display : 'None',  
        downloadBtn2Display : 'None',
        findBtnDisable : true,
        downloadBtnDisplay : 'None',
        reportUtilKeyword: '',
        reportUtilChangeKeyword: '',
        rbDisplay: false,
        changeDiv: false,
        isMobile : this.$isMobile(),
        getPropertyData : {
          표: {            
            선모양 : g선모양,
            선두께: g선두께,
            선색: g선색,
          },
          표셀: {
            배경투명: g배경투명,
            배경색: g선색,
            출력양식: '',
            폰트명: '',
            폰트크기: '',
            글자색: g선색,
            자간: '',
            왼쪽선모양: g선모양,
            왼쪽선두께: g선두께,
            왼쪽선색: g선색,
            오른쪽선모양: g선모양,
            오른쪽선두께: g선두께,
            오른쪽선색: g선색,
            위쪽선모양: g선모양,
            위쪽선두께: g선두께,
            위쪽선색: g선색,
            아래쪽선모양: g선모양,
            아래쪽선두께: g선두께,
            아래쪽선색: g선색,
            상향대각선모양: g선모양,
            상향대각선두께: g선두께,
            상향대각선색: g선색,
            하향대각선모양: g선모양,
            하향대각선두께: g선두께,
            하향대각선색: g선색,
            왼쪽여백: '',
            오른쪽여백: '',
            위쪽여백: '',
            아래쪽여백: '',
          },
          라벨: {
            배경투명: g배경투명,
            배경색: g선색,
            출력양식: '',
            폰트명: '',
            폰트크기: '',
            글자색: g선색,
            자간: '',
            선모양: g선모양,
            선두께: g선두께,
            선색: g선색,
            왼쪽여백: '',
            오른쪽여백: '',
            위쪽여백: '',
            아래쪽여백: '',
          }
        },
        selectedCategory: null,
        selectedSubcategory: null,
        getPropertyCnm: '',
        isGetPropertyC: false,
        getPropertyPnm: '',     
        isPropertyP: false,
        isGetPropertyPV: false,
        getPropertyPVnm: '',
        isGetPropertyPV2: false,
        getPropertyPVnm2: '',
        color: '',
        isColorPicker: false,
        color2: '',
        isColorPicker2: false,
        inputPropVal :'',
        inputPropVal2 :'',
        inputPropValDisplay : "None",
        inputPropVal2Display : "None",
        inputPropValPlaceholder :  '',
      };
    },
    props: {
    },
    components: {   
      LoadingBar,
    },
    created() {      
    },    
    computed: { 
      getPropertyValues() {
        const propertyValues = this.getPropertyData[this.getPropertyCnm][this.getPropertyPnm];
        if (Array.isArray(propertyValues)) {
            // 배열일 경우 각 객체의 label과 code를 매핑하여 반환
            return propertyValues.map(value => ({ label: value.label, code: value.code }));
        }
        // 값이 단일 문자열인 경우 (예: 'RGB')
        return [{ label: propertyValues, code: propertyValues }];
      }
    },
    mounted() {
      
    },
    methods: { 
      clickFileUpload(){        
        this.$refs.fileInput.value = ''; 
        this.isUpload = false;
        this.reportUtilTypeDisable = true;
        this.isGetPropertyC = false;
        this.isGetPropertyP = false;
        this.isGetPropertyPV = false;
        this.isColorPicker = false;
        this.inputPropValDisplay = 'None';
        this.reportUtilKeywordDisplay = 'None';

      },
      setFileUpload() {     
        this.btnUploadDisabled=false;
        this.resultText= '';    
        this.reportUtilType= '';
        this.findBtnDisable=true;        
        this.downloadBtnDisplay='None';
        this.changeDiv=false;
        var frm = new FormData();
        var file_doc = document.getElementById("uploadFile");       
        var file = file_doc.files[0];			
        if(file!=null && file!=undefined){				
          var fileLimit = 500;
          if (file.size > fileLimit * 1024 * 1024) {  // 500MB = 500 * 1024 * 1024 bytes
              this.$messageShow("업로드 용량 제한 : "+fileLimit+"MB", 'error');
          }
          else {
            frm.append("uploadFile", file);							
            frm.append('uuidFilename', '');
            frm.append('uploadType','reportUtil');
            this.frmUpload = frm;       
          }
        }			
		  },
      async uploadFiles() {
        this.btnUploadDisabled = true;

        try {		
          if (this.frmUpload !== null) {
            this.loading = true;    
            const response = await axios.post('/biz/common/fileUpload', this.frmUpload, {              
              headers: {
                'Content-type': 'multipart/form-data'
              }
            });
            this.$messageShow(response.data.split('\n')[0], 'warn');
            this.frmUpload = response.data.split('\n')[1];
					  this.frmUploadUuid = response.data.split('\n')[2];     
            this.reportUtilTypeDisable = false;       
            this.loading = false; 
            this.isUpload = true;
          }
        } catch (error) {          
          this.$messageShow(error.response.data, 'error');
          console.log(error);
          this.loading = false;
        }
      },
      downloadFile(downloadType) {
        this.loading == true;                
        var params = {
          supportDetailsInstallDOC : this.frmUpload,
          supportDetailsInstallDOCuuid : this.frmUploadUuid.substring(0,this.frmUploadUuid.indexOf(".")),
          downloadType : downloadType,
        }
        axios.post('/biz/common/download', params, {
            responseType: 'arraybuffer' // 바이너리 데이터로 응답 받기 위해 responseType을 'arraybuffer'으로 설정
        }).then(response => {
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);

            // 서버에서 받은 파일명 가져오기
            const contentDisposition = response.headers['content-disposition'];
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            const filename = matches != null && matches[1] ? decodeURIComponent(matches[1].replace(/['"]/g, '')) : 'download_error'; // 서버에서 파일명이 없을 경우 기본값으로 'downloaded_file.png' 설정

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename); // 다운로드될 파일 이름 설정
            document.body.appendChild(link);
            link.click();
            this.loading == false;
        }).catch(error => {
            console.error('파일 다운로드 중 오류 발생:', error);
        });
      },
      findReportUtil(){             
        var self = this;  
        this.resultText = "";
        this.downloadBtn2Display = "None";        
        if(this.reportUtilType.isInputKeyword && this.reportUtilKeyword==""){
          this.$messageShow("해당 기능은 키워드가 필수 입니다.", 'warn');
        } else {       
          var path = "";     
          this.loading = true;
          path = this.frmUploadUuid.substring(0,this.frmUploadUuid.indexOf("."));
          
          var param = {	          
            "unzipPath" : path,
            "reportUtilType" : this.reportUtilType.code,
            "reportUtilKeyword" : this.reportUtilKeyword,
            "range" : this.range,
          };                        
          this.$post(`/biz/reportutil/get`, param,
            function(response, i){              
              self.$messageShow('찾기완료', 'success');
              i.resultText = response.data;
              i.downloadBtnDisplay = 'block'; 
              i.reportUtilKeyword2Display = i.reportUtilType.isInputKeyword2 == false ? 'None' : 'block';
              i.changeDiv = i.reportUtilType.isChange == false ? false : true;
              i.isGetPropertyPV2 = i.isGetPropertyPV;
              i.isColorPicker2 = i.isColorPicker;
              i.getPropertyPVnm2 = i.getPropertyPVnm;
              i.inputPropVal2Display = i.inputPropValDisplay;
              i.loading = false;
            },
            function(e, i){
              i.loading = false;
              self.$messageShow(e.message, 'error');
              console.log(e);              
            })
        }
      },changeReportUtil(){   
        var startDate = new Date();        
        var self = this;  
        this.downloadBtnDisplay = "None";
        this.resultText = "";        
        
        if(this.reportUtilType.isInputKeyword && this.reportUtilChangeKeyword==""){
          this.$messageShow("해당 기능은 키워드가 필수 입니다.", 'warn');
        } else if(this.reportUtilKeyword === this.reportUtilChangeKeyword){
          this.$messageShow("바꾸려는 값이 같습니다.", 'warn');
        } else {            
          this.loading = true;     
          var param = {	          
            "unzipPath" : this.frmUploadUuid.substring(0,this.frmUploadUuid.indexOf(".")),
            "reportUtilType" : this.reportUtilType.code.replace("get", "change"),
            "reportUtilKeyword" : this.reportUtilKeyword,
            "reportUtilChangeKeyword" : this.reportUtilChangeKeyword,
            "range" : this.range,
          };                        
          this.$post(`/biz/reportutil/change`, param,
            function(response, i){
              var elapsedTime = i.elapedTime(startDate);
              self.$messageShow('변환완료\n 걸린시간 : '+ elapsedTime, 'success');
              i.resultText = response.data;
              i.downloadBtn2Display = 'block'; 
              i.loading = false;
            },
            function(e, i){
              self.$messageShow(e.message, 'error');
              console.log(e);
              i.loading = false;
            })
        }
      },elapedTime(startDate){
        var endDate = new Date();
        const diff = endDate - startDate;
        const hours = Math.floor(diff / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        return `${hours}시간 ${minutes}분 ${seconds}초`;
      },
      changeDropdown(){
        this.reportUtilKeywordDisable=false;
        this.findBtnDisable=false;
        this.rbDisplay = this.reportUtilType.rbDisplay;
        this.reportUtilKeyword = "";
        this.reportUtilChangeKeyword = "";   
        this.changeDiv = false;   
        this.isGetPropertyC = false;
        this.isGetPropertyP = false;
        this.isGetPropertyPV = false;
        this.isColorPicker = false;
        this.isColorPicker2 = false;
        this.inputPropValDisplay = 'None';
        this.inputPropVal2Display = 'None';
        this.getPropertyCnm = '';
        
        this.isGetPropertyC = this.reportUtilType.isInputPropDropKeyword == true ? true : false;        
        if(this.reportUtilType.range == null) {
          this.range = 'total';
          this.reportUtilKeywordDisplay = 'None'
        } else {
          this.range = this.reportUtilType.range;
          if(this.reportUtilType.isInputKeyword == false) {
            this.reportUtilKeywordDisplay = 'None'
            this.reportUtilKeywordDisplay = 'None'
          } else {
            this.reportUtilKeywordDisplay = 'block'
          }          
        }          
      }, changeRadio(){
        this.range == 'each' ? this.reportUtilKeywordDisplay = 'block' : this.reportUtilKeywordDisplay = 'None'
      }, changePropCDropdown(){        
        this.isGetPropertyP = this.reportUtilType.isInputPropDropKeyword == true ? true : false;
        this.getPropertyPnm = this.getPropertyCnm;                
        this.isColorPicker = false;
        this.isGetPropertyPV = false;
        this.reportUtilKeyword = "";
        this.getPropertyPVnm = "";
        this.findBtnDisable = true;
        this.changeBtnDisable = true;
        this.changeDiv = false;
        this.inputPropValDisplay = 'None';
      }, changePropPDropdown(){           
        this.findBtnDisable = true;     
        if(this.getPropertyPnm == "출력양식" || this.getPropertyPnm == "폰트명"
        || this.getPropertyPnm == "폰트크기" || this.getPropertyPnm == "자간"
        || this.getPropertyPnm == "왼쪽여백" || this.getPropertyPnm == "오른쪽여백"
        || this.getPropertyPnm == "위쪽여백" || this.getPropertyPnm == "아래쪽여백") {
          this.isGetPropertyPV = false;
          this.inputPropValDisplay = 'block';

          this.inputPropValPlaceholder = this.getPropertyPnm;
        } else {          
          this.isGetPropertyPV = this.reportUtilType.isInputPropDropKeyword == true ? true : false;          
          this.inputPropValDisplay = 'None';
          this.findBtnDisable = true;
        }
        this.isColorPicker = false;
        this.getPropertyPVnm = "";
        this.reportUtilKeyword = "";
        this.changeDiv = false;        
      }, changePropPVDropdown(){
        this.findBtnDisable = true;
        this.changeBtnDisable = true;
        
        if(this.getPropertyPVnm.label == "색상 직접 선택"){
          this.isColorPicker = true;
          this.inputPropValDisplay = "None";          
        } else if (this.getPropertyPVnm.code == "rgb" || this.getPropertyPVnm.code == "hex"){
          this.inputPropValPlaceholder = this.getPropertyPVnm.code;          
          this.inputPropValDisplay = "block";
          this.isColorPicker = false;
        } else {                    
          this.findBtnDisable = false;
          this.isColorPicker = false;
          this.reportUtilKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.getPropertyPVnm.code;
        }
      }, changePropPV2Dropdown(){        
        this.changeBtnDisable = false;
        this.reportUtilChangeKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.getPropertyPVnm2.code; 
        
        if(this.getPropertyPVnm2.label == "색상 직접 선택"){
          this.isColorPicker2 = true;
          this.inputPropVal2Display = "None";          
        } else if (this.getPropertyPVnm2.code == "rgb" || this.getPropertyPVnm2.code == "hex"){
          this.inputPropValPlaceholder = this.getPropertyPVnm2.code;          
          this.inputPropVal2Display = "block";
          this.isColorPicker2 = false;
        } else {                    
          this.changeBtnDisable = false;
          this.isColorPicker2 = false;
          this.reportUtilChangeKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.getPropertyPVnm2.code;
        }
      }, changeColorPicker(){
        this.findBtnDisable = false;        
        this.reportUtilKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.colorToDecimal(this.color);
      }, changeColorPicker2(){        
        this.changeBtnDisable = false;        
        this.reportUtilChangeKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.colorToDecimal(this.color2);        
      }, colorToDecimal(color) {
        // 헥사코드일 경우
        if (typeof color === 'string' && (color.startsWith('#') || /^[0-9a-fA-F]{6}$/.test(color))) {
          if (color.startsWith('#')) {
            color = color.slice(1);
          }

          const red = parseInt(color.slice(0, 2), 16);
          const green = parseInt(color.slice(2, 4), 16);
          const blue = parseInt(color.slice(4, 6), 16);

          // BGR 순서로 변환하여 반환
          return (blue << 16) + (green << 8) + red;
        } 
        // RGB 문자열 코드일 경우
        else if (typeof color === 'string' && color.includes(',')) {
          const [red, green, blue] = color.split(',').map(Number);

          // BGR 순서로 변환하여 반환
          return (blue << 16) + (green << 8) + red;
        } 
        // RGB 객체일 경우
        else if (typeof color === 'object' && 'r' in color && 'g' in color && 'b' in color) {
          const { r, g, b } = color;

          // BGR 순서로 변환하여 반환
          return (b << 16) + (g << 8) + r;
        } 
        // 입력 형식이 잘못된 경우
        else {
          throw new Error('Invalid color format');
        }
      }, validate(gubun,inputPropVal){
        if(this.getPropertyPnm == "자간" || this.getPropertyPnm == "폰트크기" || this.getPropertyPnm.indexOf("여백") != -1) {  
          var numberPattern;
          if(this.getPropertyPnm == "자간") {
            numberPattern = /^-?\d+(\.\d+)?$/;
          } else if (this.getPropertyPnm.indexOf("여백") != -1){
            numberPattern = /^\d+(\.\d+)?$/;
          } else {
            numberPattern = /^\d+$/;
          }
          console.log(numberPattern);
          console.log(numberPattern.test(inputPropVal));
          if(!numberPattern.test(inputPropVal)) {
            this.$messageShow("값을 알맞게 입력하세요.", 'warn');
            if(gubun == "find") {
              this.findBtnDisable = true;
            } else if(gubun == "change") {
              this.changeBtnDisable = true;            
            }
          } else {            
            if(gubun == "find") {              
              this.reportUtilKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+inputPropVal;
              this.findBtnDisable = false;              
            } else if(gubun == "change") {
              this.reportUtilChangeKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.inputPropVal2;
              this.changeBtnDisable = false;              
            }
          }
        } else if (this.getPropertyPnm.indexOf("색") != -1){
          this.validateColor(gubun, inputPropVal);
        } else {          
          if(gubun == "find") {              
            this.reportUtilKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+inputPropVal;
            this.findBtnDisable = false;              
          } else if(gubun == "change") {
            this.reportUtilChangeKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.inputPropVal2;
            this.changeBtnDisable = false;              
          }
        }

      
      }, validateColor(gubun, inputColor){        
        // RGB 패턴: 0-255,0-255,0-255 (rgb 텍스트 없음)
        const rgbPattern = /^(0|[1-9]\d?|1\d{2}|2[0-4]\d|25[0-5]),(0|[1-9]\d?|1\d{2}|2[0-4]\d|25[0-5]),(0|[1-9]\d?|1\d{2}|2[0-4]\d|25[0-5])$/;      
        
        // Hex 패턴: #RRGGBB 또는 #RGB
        const hexPattern = /^#([0-9A-Fa-f]{6}|[0-9A-Fa-f]{3})$/;

        if ((rgbPattern.test(inputColor) && this.getPropertyPVnm.code == "rgb") || (hexPattern.test(inputColor) && this.getPropertyPVnm.code == "hex")) {
          if(gubun == "find") {
            this.findBtnDisable = false;
            this.reportUtilKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.colorToDecimal(inputColor);
          } else if(gubun == "change") {
            this.changeBtnDisable = false;
            this.reportUtilChangeKeyword = this.getPropertyCnm +"|,|"+this.getPropertyPnm+"|,|"+this.colorToDecimal(inputColor);
          }
          
        } else {
          if(gubun == "find") {
            this.findBtnDisable = true;
          } else if(gubun == "change") {
            this.changeBtnDisable = true;            
          }
          this.$messageShow("0-255,0-255,0-255 or #ff0000 format", 'warn');
        }
      
      }
    },    
  };
  </script>
  
  <style lang="css" scoped>
    .container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 0px;
    justify-content: left
  }
  .container * {
    margin-left : 5px;
  }

  
  </style>