import { createApp } from 'vue'
import App from './App.vue'
import 'bootstrap'
import router from './router.js'
import common from './assets/js/common'
import store from './assets/js/store'
import PrimeVue from 'primevue/config';
import "primevue/resources/primevue.min.css"; /* Deprecated */
import "primevue/resources/themes/lara-light-blue/theme.css";
import "primeicons/primeicons.css";
import 'bootstrap/dist/css/bootstrap.css'
import './assets/dist/css/common.css';
import AutoComplete from 'primevue/autocomplete';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab'
import Textarea from 'primevue/textarea';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import OverlayPanel from 'primevue/overlaypanel';
import Listbox from 'primevue/listbox';
import MultiSelect from 'primevue/multiselect';
import Timeline from 'primevue/timeline';
import SelectButton from 'primevue/selectbutton';
import Tag from 'primevue/tag';
import VueCookies from 'vue-cookies';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Badge from 'primevue/badge';
import FileUpload from 'primevue/fileupload';
import Menu from 'primevue/menu';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import ToggleButton from 'primevue/togglebutton';
import RadioButton from 'primevue/radiobutton';
import Chip from 'primevue/chip';
import ColorPicker from 'primevue/colorpicker';
import './registerServiceWorker'

createApp(App)
.use(common)
.use(store)
.use(router)
.use(PrimeVue, { ripple: true  })
.use(ToastService)
.use(VueCookies)
.component('AutoComplete', AutoComplete)
.component('Button', Button)
.component('Dialog', Dialog)
.component('Divider', Divider)
.component('Dropdown', Dropdown)
.component('Accordion', Accordion)
.component('AccordionTab', AccordionTab)
.component('Textarea', Textarea)
.component('Checkbox', Checkbox)
.component('Calendar', Calendar)
.component('InputNumber', InputNumber)
.component('InputText', InputText)
.component('Toast', Toast)
.component('Splitter', Splitter)
.component('SplitterPanel', SplitterPanel)
.component('OverlayPanel', OverlayPanel)
.component('Listbox', Listbox)
.component('MultiSelect', MultiSelect)
.component('Timeline', Timeline)
.component('SelectButton', SelectButton)
.component('Tag', Tag)
.component('VueCookies', VueCookies)
.component('TabView', TabView)
.component('TabPanel', TabPanel)
.component('Badge', Badge)
.component('FileUpload', FileUpload)
.component('Menu', Menu)
.component('PanelMenu', PanelMenu)
.component('Password', Password)
.component('ToggleButton', ToggleButton)
.component('RadioButton', RadioButton)
.component('Chip', Chip)
.component('ColorPicker', ColorPicker)


.mount('#app')