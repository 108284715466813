<template>
  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">메뉴 관리 - 팀, 직원</h1>
  </div>
  <!-- 검색 조건 -->
  <div class="container">
    <input type="radio" id="'A'" v-model="teamSelect" :value=true @change="changeRadio">
			<label class="center" :for="'A'">팀</label>
    <input type="radio" id="'U'" v-model="teamSelect" :value=false @change="changeRadio">
      <label class="center" :for="'U'">개인</label>      
      <input v-model="searchText" class="form-control" type="text" placeholder="이름 / 회사 / 부서 / 팀"  @keyup.enter="changePage(1, this.pagination.pageSize)" :disabled="teamDisabled" />	  
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText" ></Button>		          
  </div>

	<div class="container">
    <!-- 직원 단위 설정 -->	    
	  <table class="table table-bordered table-hover caption-top" v-if="teamSelect == false && tableVisible == true">
	    <thead class="table-light">
	      <tr class="table-primary">          
            <th scope="col" v-for="item in header" :key="item">
                {{ item }}
            </th>
        </tr>
      </thead>      
      <tbody class="table-group-divider">        
          <tr v-for="(row) in menu2" :key="row">
            <td class="center"  style="min-width: 70px;">{{row.name}}</td>
            <td class="center" style="min-width: 70px;">{{row.company}}</td>
            <td class="center" style="min-width: 120px;">{{row.department}}</td>
            <td class="center" style="min-width: 140px;">{{row.team}}</td>                 
            <td>     
              <div style="display: flex; align-items: center;">
              <!-- <MultiSelect v-model="row.menuSeq" :style="{ 'max-width': '100%'} " display="chip" @blur="handleBlur" @change="changeMenu(row.userEmail, row.menuSeq)" :options="menuName" optionLabel="menuName" placeholder="메뉴 선택" class="w-full md:w-20rem" style="font-size:12px;" /> -->
              <MultiSelect v-model="row.menuSeq" :style="{ 'max-width': '100%'} " display="chip" scrollHeight= "500px" :maxSelectedLabels="3" @blur="handleBlur" :options="menuName" optionLabel="menuName" placeholder="메뉴 선택" class="w-full md:w-20rem" style="font-size:12px;" />
                <!-- <SelectButton v-model="row.menuSeq" :options="menuName" optionLabel="menuName" multiple aria-labelledby="multiple"  :disabled="isUse(row.useYn)"  /> -->
                <Button icon="pi pi-save" severity="contrast" aria-label="Submit" @click="changeMenu(row.userEmail, row.menuSeq)" style="margin-left:10px" :disabled="isUse(row.useYn)" />
              </div>              
            </td>
          </tr>        
      </tbody>
    </table>
    <!-- 팀 단위 설정 -->	    
    <table class="table table-bordered table-hover caption-top" v-if="teamSelect == true && tableVisible == true">
	    <thead class="table-light">
	      <tr class="table-primary">          
            <th scope="col" v-for="item in header2" :key="item">
                {{ item }}
            </th>
        </tr>
      </thead>      
      <tbody class="table-group-divider">        
          <!-- <tr v-for="(row) in menu2" :key="row">            -->
          <tr>
            <td class="center" style="min-width: 140px;">                      
              <Dropdown v-model="selectedTeamName" editable :options="menu2" scrollHeight="350px" optionLabel="team" placeholder="팀을 선택해 주세요." class="w-full md:w-14rem" />
            </td>
            <td>     
              <div style="display: flex; align-items: center;">      
                <MultiSelect v-model="selectedTeamName.menuSeq" :style="{ 'max-width': '100%'} " display="chip" scrollHeight= "500px" :maxSelectedLabels="3" @blur="handleBlur" :options="menuName" optionLabel="menuName" placeholder="메뉴 선택" class="w-full md:w-20rem" style="font-size:12px;" />
                <!-- <SelectButton v-model="selectedTeamName.menuSeq" :options="menuName" optionLabel="menuName" multiple aria-labelledby="multiple" /> -->
                <Button icon="pi pi-save" severity="contrast" aria-label="Submit" @click="changeMenu(selectedTeamName.userEmail, selectedTeamName.menuSeq)" style="margin-left:10px" />
              </div>              
            </td>
          </tr>        
      </tbody>
    </table>
   </div>
  <div class="container" v-if="teamSelect == false && tableVisible == true">
	  <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
	  <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
  </div>
</template>
<script>
import Pagination from '@/components/Common/ClipPagination.vue'
import mPagination from '@/components/Common/mClipPagination.vue'
export default {
name: 'MenuMain',
data(){
  return {
    menu: null,
    menu2: null,
    header: ["이름","회사","부서","팀","메뉴권한"],
    header2: ["팀","메뉴권한"],
    main: true,
    menuManag: false,
    userManag: false,
    proMenuSeq : '',
    currentPage: 1,        // 현재 페이지
    itemsPerPage: 8,      // 페이지당 항목 수
    itemsTotCnt: 0,      // 전체 항목 수
    total: 0,      // 페이지당 항목 수
    pagination: {
    		endRow: 0,
		    hasNextPage: false,
		    hasPreviousPage: false,
		    isFirstPage: true,
		    isLastPage: true,
		    navigateFirstPage: 0,
		    navigateLastPage: 0,
		    navigatePages: 8,
		    navigatepageNums: [],
		    nextPage: 0,
		    pageNum: 1,
		    pageSize: this.isMobile ? 5 : 10,
		    pages: 0,
		    prePage: 0,
		    size: 0,
		    startRow: 0,
		    total: 0
    	},
      isMobile : this.$isMobile(),
      searchText: '',
      menuName: [],
      selectedItems: [],
      previousSelection: [],
      teamSelect: false,
      teamDisabled: false,
      tableVisible: false,
      selectedTeamName:'',
  };
},
components: {
  Pagination,
  mPagination,
},
created(){
  var paramData = {
    	"pageNum": 1,
		"pageSize": this.isMobile ? 5 : this.itemsPerPage,		
		"name": "",
    "company": "",
    "department": "",
    "team": ""
    };
    
  
  this.$get('/biz/menu/list', paramData,
	                function(response, i){
	                	i.menu = response.data.result; 
                    i.menu = i.menu.filter(menu => menu.systemType !== "A");
                    i.menu.sort((a, b) => a.sort - b.sort);
                    i.menuName = i.menu.map(menu => ({ menuSeq: menu.menuSeq, menuName: menu.menuName }));                    
	                },
	                function(e, i){
	                	console.log(e);
	                }
  );  
  this.pagination.pageSize = this.isMobile ? 5 : this.itemsPerPage;  
},
computed: {
    sortedMenu() {
      if (this.menu) {
        return this.menu.slice().sort((a, b) => a.sort - b.sort);
      } else {
        return [];
      }
    },
},
methods: {
  //사용여부 
  usech(menuSeq){
	  
	  var paramUseYn = this.$refs['useYn-'+menuSeq][0].checked ? 'Y' : 'N';
	    
	  var paramData = {
	        "menuSeq": menuSeq,
	        "useYn": paramUseYn
	  };
	  
	  this.$post('/biz/menu/mod/use-yn', paramData,
		                function(response, i){
		                	console.log(response.status);
		                },
		                function(e, i){
		                	console.log(e);
		                }
	  );
  },
  //시스템타입
  systemtype(menuSeq, systemType){
    var paramData = {
        "menuSeq": menuSeq,
        "systemType": systemType
	};
		  this.$post('/biz/menu/mod/system-type', paramData,
	                function(response, i){
	                },
	                function(e, i){
	                	console.log(e);
	                }
  		);
  },  
  //메뉴 상세페이지 이동
  clickList(menuSeq) {
    this.$router.push({
      name: "DtldMngmn",
      query: { 
        menuSeq:  menuSeq,
      },
    });
  },
  setHoverText(text) {
    this.hoverText = text;
  },
  clearHoverText() {
    this.hoverText = '';
  },
  // 페이지 변경 시 호출될 함수
  changePage(newPage, pageSize) {        
    this.selectedTeamName = '';
    this.tableVisible = true;     
    this.currentPage = newPage;    
    var paramData = {
      "pageNum": this.currentPage,
      "pageSize": pageSize,
      "name": this.searchText,
      "company": this.searchText,
      "department": this.searchText,
      "team": this.searchText,
      "teamSelect": this.teamSelect,
    };

    this.$get('/biz/account/list2', {
                params: paramData
              },
      function(response, i){	               
        i.menu2 = response.data.result2.list;
        i.pagination = response.data.result2;               

        const converted_menu = i.menu2.map(user => {
          // menuSeq가 null 또는 빈 문자열인 경우 빈 배열로 처리
          const menuSeqArray = user.menuSeq ? user.menuSeq.split(',').map(Number) : [];
          
          const userMenuSeq = menuSeqArray.map(seq => {
            const foundMenu = i.menu.find(item => item.menuSeq === seq);
            // menuSeq에 해당하는 메뉴를 찾은 경우에만 객체로 반환
            if (foundMenu) {
                return { "menuSeq": foundMenu.menuSeq, "menuName": foundMenu.menuName };
            }
            // 메뉴를 찾지 못한 경우에는 null 대신 빈 객체 반환
            return { "menuSeq": null, "menuName": "" };
          });        
          return { ...user, "menuSeq": userMenuSeq };
        });
        i.menu2 = converted_menu;                
      },
      function(e, i){
        console.log(e);
      }
    );
     
  }, changeMenu(userEmail, menuSeq){            
    if(this.selectedTeamName=='' && this.teamSelect == true){
				this.$messageShow('팀을 선택 해주세요.', 'warn');				
    } 
    else{
      var self = this;
      var delURLStr = this.teamSelect == false ? 'del' : 'delTeam';
      var addURLStr = this.teamSelect == false ? 'add' : 'addTeam';
      var completedRequests = 0;

      var paramData1 = this.teamSelect == false? {
              "list": [
                {
                  "userEmail": userEmail
                }
              ]
        } : {
              "list": [
                {
                  "teamCode": this.selectedTeamName.teamCode
                }
              ]
        }
      //권한삭제 API 호출      	
      this.$post(`/biz/menu/auth/${delURLStr}`, paramData1,
        function(response){
          for(var j = 0; j<menuSeq.length; j++){
            var paramData2 = self.teamSelect == false ? {
                  "list": [
                    {
                      "userEmail": userEmail,
                      "menuSeq": menuSeq[j].menuSeq,
                      "permissionType": 'A'
                    }
                  ]
            } : {
                  "list": [
                    {
                      "teamCode": self.selectedTeamName.teamCode,
                      "menuSeq": menuSeq[j].menuSeq,
                      "permissionType": 'A'
                    }
                  ]
            };
            //권한추가 API 호출            
            self.$post(`/biz/menu/auth/${menuSeq[j].menuSeq}/${addURLStr}`, paramData2,
                      function(response){
                        completedRequests++;

                        // 마지막 반복에서만 체크
                        if (completedRequests === menuSeq.length) {
                          self.$toast.add({ severity: 'success', summary: '성공', detail: '메뉴변경', life: 1000 });    
                          self.changePage(self.currentPage, self.pagination.pageSize);
                        }                   
                      },
                      function(e){
                        console.log(e);
                      }
            );
          }    
        },
        function(e){
          console.log(e);
        }
      );
    }
  },isUse(useYn){    
    return useYn =='Y' ? false : true;
  },changeRadio(){    
    this.tableVisible = false;
    this.searchText = '';    
    this.teamDisabled = this.teamSelect == true ? true : false;    
    this.pagination.pageSize = this.teamSelect == true ? this.pagination.total : this.itemsPerPage;
  },
}
};
</script>

<style lang="css" scoped>
/* .p-button 클래스는 PrimeVue의 버튼 스타일을 나타냅니다. */
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center;  ;
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
  vertical-align: middle;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}  
}

th{
  text-align: center;
}
tr{
  cursor: pointer;
}

</style>