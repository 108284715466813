
<template>    
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
      <h1 class="h2">기술지원 내역 - RANK
      </h1>
    </div>
  
    <!-- 검색 조건 -->
    <div class="container" v-if="isMobile == false">	 
      <input v-model="searchYear" class="form-control" type="text" placeholder="년 (YYYY)" @keyup.enter="changePage(1, this.pagination.pageSize)" style="width:200px" />
      <Dropdown v-model="searchMonth" editable scroll-height="500px"  :options="searchMonths" optionLabel="name" placeholder="월 / 분기 / 반기 (한달 단위 검색은 입력 1~12)" @keyup.enter="changePage(1, this.pagination.pageSize)" class="w-full md:w-14rem" style="width:600px" />  
      <Dropdown v-model="supportName" editable scroll-height="500px"  :options="supportNames" optionLabel="name" placeholder="작업자" class="w-full md:w-14rem" style="width:400px" />  
      <!-- <Dropdown v-model="businessTypeName" editable scroll-height="500px" :options="businessTypes" optionLabel="label" placeholder="사업구분" class="w-full md:w-14rem" style="width:400px" />   -->
      <div style="display:flex; justify-content: flex-end; width:400px;">
      <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText" style="margin-right:10px;" ></Button>		
      <Button class="pi pi-times-circle" severity="success" @click="unsetSearchData" @mouseover="setHoverText('검색 초기화')" @mouseout="clearHoverText" :title="hoverText" style="margin-right:10px;"></Button>
      <!-- <Button class="pi" @click="exportXls($event)" style="background:#ffffff; display:flex; justify-content:center; align-items:center;">
        <img src="@/assets/excel.svg" alt="Excel Icon" style="width: 25px; height: 25px;" />
      </Button> -->
    </div>
    </div>
    
    <!-- 본문 -->
    <div class="container" v-if="isMobile == false">	 
        <table class="table table-bordered table-hover caption-top">
          <thead class="table-light">
            <tr class="table-primary">
              <th scope="col" v-for="item in header" :key="item" class="center">
                {{ item }}
            </th>
          </tr>
        </thead>
        <tbody class="table-group-divider">
          <tr v-for="(row, i) in rank" :key="i"  >            
            <td class="center" style="cursor: pointer; text-overflow:ellipsis;">{{ row.companyName.length > 8 ? row.companyName.substring(0,8) + '...' : row.companyName }}</td>
            <td class="center" style="cursor: pointer; text-overflow:ellipsis;">{{ row.projectName.length > 8 ? row.projectName.substring(0,8) + '...' : row.projectName }}</td>
            <td class="center" style="cursor: pointer; text-overflow:ellipsis;">{{ row.countPerProject }}</td>
            <td class="center" style="cursor: pointer; text-overflow:ellipsis;">{{ row.rank }}</td>
            
          </tr>
        </tbody>
      </table>
    </div> 
    <div class="container" v-if="isMobile == true">
      <Chip label="모바일로 보기 좁아요. PC로 보세요." icon="pi pi-megaphone" style="margin-left: 15px;" />
      <a href="/biz" >
        <Button icon="pi pi-home" severity="secondary" style="width:2rem;height:2rem;"  />
      </a>
    </div>
    <div class="container">
        <LoadingBar :loading= "loading" v-if="loading == true"></LoadingBar>
        <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
        <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
        <Badge :value="pagination.total" severity="warning" v-if="isMobile == false" style="margin-top:20px"></Badge>
    </div>
  </template>
  
  <script>  
  import Pagination from '@/components/Common/ClipPagination.vue'
  import mPagination from '@/components/Common/mClipPagination.vue'
  import LoadingBar from '@/components/Common/LoadingBar.vue'
  import { saveAs } from 'file-saver'
  import * as XLSX from 'xlsx'
  
  export default {
    name: 'Rank',
    data() {
      return {                
        header: ["고객사", "프로젝트","건수","랭킹"],     
        update: false,
        searchYear: new Date().getFullYear(),
        searchMonth: "",
        searchMonths: [
                        {
                          name: '1/4분기',
                          code: 'Q1'
                        },
                        {
                          name: '2/4분기',
                          code: 'Q2'
                        },
                        {
                          name: '3/4분기',
                          code: 'Q3'
                        },
                        {
                          name: '4/4분기',
                          code: 'Q4'
                        },
                        {
                          name: '상반기',
                          code: 'H1'
                        },
                        {
                          name: '하반기',
                          code: 'H2'
                        },         
            ],
        currentPage: 1,        // 현재 페이지
        itemsPerPage: 10,      // 페이지당 항목 수
        total: 0,      // 페이지당 항목 수
        pagination: {
              endRow: 0,
              hasNextPage: false,
              hasPreviousPage: false,
              isFirstPage: true,
              isLastPage: true,
              navigateFirstPage: 0,
              navigateLastPage: 0,
              navigatePages: 10,
              navigatepageNums: [],
              nextPage: 0,
              pageNum: 1,
              pageSize: this.isMobile ? 5 : 10,
              pages: 0,
              prePage: 0,
              size: 0,
              startRow: 0,
              total: 0
          },      
        hoverText: '',
        rank: {},
        businessTypeName: '',
        businessTypes: {},
        isMobile : this.$isMobile(),
        supportName:'',
        supportNames:{},
        loading: false,
      };
    },
    props: {
    },
    components: {      
      Pagination,
      mPagination,
      LoadingBar,
    },
    created() {      
      this.$code(["BUSINESS_TYPE"], false,
        function(result, i){          
              i.businessTypes = result.businessType;
            },
            function(e, i){
              console.log(e);
            }
      )
    },    
    computed: {
    },
    mounted() {
      this.$nextTick(function() {
        this.getTeamAccount();    
      });
    },
    methods: {      
      // 페이지 변경 시 호출될 함수      
      changePage(newPage, pageSize) {        
        this.currentPage = newPage;        
        var paramData = {
          "pageNum": newPage,
          "pageSize": pageSize,
          "searchYear": this.searchYear,
          "searchMonth": this.searchMonth.code != undefined ? this.searchMonth.code : this.searchMonth,
          "searchSupportName": this.supportName.name == undefined ? "" : this.supportName.name,
          //"searchBusinessType": this.businessTypeName.value == undefined ? "" : this.businessTypeName.value,
          };      
          this.loading = true;
      this.$get('/biz/rank/list', {
                      params: paramData
                      },
                  function(response, i){
                      i.rank = response.data.pagination.list;   //기존데이터에 추가
                      i.pagination = response.data.pagination;
                      i.loading = false;
                  },
                  function(e, i){
                      console.log(e);
                      i.loading = false;
                  }
       );
       
      },
      popupDetailModal(row){
          this.licenseDetailModal = true;
          this.detailsLicenseSeq = row.seq;
      },    
      unsetSearchData(){
          this.searchYear = "";
          this.searchMonth = "";
          this.supportName = "";          
          this.businessTypeName = "";
      },
      setHoverText(text) {
        this.hoverText = text;
      },
      clearHoverText() {
        this.hoverText = '';
      },
      exportXls() {
              var paramData = {
                "pageNum": 1,
                "pageSize": 1000000,
                "searchYear": this.searchYear,
                "searchMonth": this.searchMonth.code != undefined ? this.searchMonth.code : this.searchMonth,
                "searchSupportName": this.supportName.name == undefined ? "" : this.supportName.name,
                //"searchBusinessType": this.businessTypeName.value == undefined ? "" : this.businessTypeName.value,
              };
  
              this.$get('/biz/rank/list', {
                params: paramData
              },
              (response) => {
                const data = response.data.pagination.list;                
                const worksheet = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(data)));
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  
                const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
                const dataBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8' });
                const downloadFileName = '기술지원 통계'
                + (this.searchYear != "" ? "-"+this.searchYear+"년" : "")
                + (this.searchMonth.code != undefined ? this.searchMonth.code : this.searchMonth+"월")
                + (this.supportName.name == undefined ? "" : "-"+this.supportName.name)
                + (this.businessTypeName.value == undefined ? "" : "-"+this.businessTypeName.value)
                +'.xlsx';                
                saveAs(dataBlob, downloadFileName);
              },
              (error) => {
                console.log(error);
              });
      },
      getTeamAccount() {
        var paramData = {
              "teamCode": "TST"
        };     
        this.$get('/biz/account/teamlist', {
                    params: paramData
                },
                    function(response, i){
                      i.supportNames = response.data.teamResult;   //기존데이터에 추가                  
                    },
                    function(e, i){
                      console.log(e);
                    }
        );
      },
    },    
  };
  </script>
  
  <style lang="css" scoped>
  .container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 0px;
    justify-content: center
  }
  .container > * {
    margin-right: 10px;
    font-size: 15px;
  }
  .container > *:last-child {
    margin-right: 0;
  }
  .container .selects {
    display: flex;
  }
  .container .selects select {
    width: 120px;
    margin-right: 10px;
  }
  .container .selects select:last-child {
    margin-right: 0;
  }
  
  .center {
    text-align: center;
  }
  .container .btn {
    width: 120px;
    height: 50px;
    font-weight: 700;
    flex-shrink: 0;
  }
  @media only screen and (max-width: 480px) {
      .container {
        display: block;
      }
      .selects {
        margin-top: 10px;
      }
      .btn {
        margin-top: 10px;
      }
  }
  
  th{
    text-align: center;
  }
  
  .pi{
    min-width: 56px;
    width: 56px;
    min-height: 50px;
    height: 50px;
  }
  </style>