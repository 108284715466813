import { createWebHistory, createRouter } from "vue-router";
import Login from './components/Login/Login.vue'
import Main from './components/Main/Main.vue'
import Test from './components/Test.vue'
import Basics from './components/Content/Basics.vue'
import Company from './components/Content/Company/CompanyMain.vue'
import Project from './components/Content/Project/ProjectMain.vue'
//신청 있는 버전
//import License from './components/Content/License/LicenseMain.vue'
//신청 없는 버전
import License from './components/Content/License/LicenseMainNoRequest.vue'
import LicenseD from './components/Content/LicenseD/LicenseDMain.vue'
import LicenseOld from './components/Content/LicenseOld/LicenseOldMain.vue'
import Account from './components/Content/Account/AccountMain.vue'
//메뉴단위
//import Menu from './components/Content/Menu/MenuMain.vue'
//팀, 직원 단위
import Menu from './components/Content/Menu/MenuMain2.vue'
import MenuDetail from './components/Content/Menu/MenuDetailMain.vue'
import UserInfo from './components/Content/User/UserInfo.vue'
import Support from './components/Content/Support/SupportMain.vue'
import Statistics from './components/Content/Statistics/StatisticsMain.vue'
import Rank from './components/Content/Statistics/Rank.vue'
import Tracking from './components/Content/Tracking/TrackingMain.vue'

import ReportInfoGetAndChange from './components/Content/Util/ReportInfoGetAndChange.vue'

import NotFound from './components/Content/NotFound.vue'

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
        path: "/test",
        component: Test,
      },
  {
    path: "/biz",
    component: Main,
    children: [
      {
        path: "",
        component: Basics,
      },
      {
        path: "company",
        name: "company",
        component: Company,
      },
      {
        path: "project",
         name: "project",
        component: Project,
      },
      {
        path: "license",
        name: "license",
        component: License,
        props: true,
      },
      {
        path: "licenseD",
        name: "licenseD",
        component: LicenseD,
        props: true,
      },
      {
        path: "licenseold",
        name: "licenseold",
        component: LicenseOld,
        props: true,
      },
      {
        path: "account",
         name: "account",
        component: Account,
      },
      {
        path: "menu",
         name: "menu",
        component: Menu,
      },
      {
        path: "DtldMngmn",
        name: "DtldMngmn",
        component: MenuDetail,
      },
      {
        path: "UserInfo",
        name: "UserInfo",
        component: UserInfo,
      },
      {
        path: "support",
         name: "support",
        component: Support,
      },
      {
        path: "statistics",
         name: "statistics",
        component: Statistics,
      },
      {
        path: "rank",
         name: "rank",
        component: Rank,
      },
      {
        path: "tracking",
         name: "tracking",
        component: Tracking,
      },
      {
        path: "reportInfoGetAndChange",
         name: "reportInfoGetAndChange",
        component: ReportInfoGetAndChange,
      },
      {
        path: "util",        
        children: [
          {
            path: "getAndChange",
            name: "repofindtInfoGetAndChange",
            component: ReportInfoGetAndChange,
          },
        ]
      },
    ]
  },
  {
    path: '/:notound(.*)',
    component: NotFound
  }
  
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(){
    return { top: 0 }
  },
});

export default router; 