
<template>    
  <transition name="fade" @closesModal="supportDetailModal = false" >
    <SupportDetail :supportNames="supportNames" :supportDetailsKey="supportDetailsKey" :isAdmin="isAdmin" :update="update" :isMobile="isMobile"  v-if="supportDetailModal" />
  </transition>
  <transition name="fade" @closesModal="licenseIssueModal = false" >
    <LicenseIssue :detailsCompanySeq="detailsCompanySeq" :detailsProjectUid="detailsProjectUid" :detailsLicenseSeq="detailsLicenseSeq" :engineerRoleCheck="engineerRoleCheck" :update="update" v-if="licenseIssueModal" />    
  </transition>

    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom"> 
      <h1 class="h2">기술지원 내역 - 추적기
      </h1>
    </div>
  
    <!-- 검색 조건 -->
    <div class="container" v-if="isMobile == false">  
      <AutoComplete v-model="searchKeywordCompany" optionLabel="companyName" dropdown placeholder="고객사명"  :suggestions="filteredCompanys" @complete="companySearch" @change="setProjectList"/>  
      <AutoComplete v-model="searchKeywordProject" optionLabel="projectName" dropdown placeholder="프로젝트명"  :suggestions="filteredProjects" @complete="projectSearch" @change="changeProject"/>            
        <input type="text" class="form-control" v-model="period" style="width:300px" placeholder="기간" disabled/>
      
      
        <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText" style="margin-right:10px;" ></Button>		
        <Button class="pi pi-times-circle" severity="success" @click="unsetSearchData" @mouseover="setHoverText('검색 초기화')" @mouseout="clearHoverText" :title="hoverText" style="margin-right:10px;"></Button>        
      
    </div>
    <!-- 검색 조건 모바일 -->
    <div class="container d-flex" v-if="isMobile == true" >
      <div class="d-flex" style="width:100%;">
      <input v-model="searchKeyword" class="form-control" type="text" placeholder="고객사명 / 프로젝트명"  @keyup.enter="changePage(1, this.pagination.pageSize)" />
        <Button class="pi pi-search" @click="changePage(1, this.pagination.pageSize)" @mouseover="setHoverText('검색')" @mouseout="clearHoverText" :title="hoverText"></Button>        
      </div>
    </div>
    
    <div class="container" v-if="isGroupedCounts">
      <table>                       
        <td v-for="(value, key) in groupedCounts" :key="key" style="margin-right: 10px;"><Tag class="p-tag p-component p-tag-info" :style="tagStyle(key)">{{ key }}</Tag> <Badge :value="value" severity="secondary" style="margin-right: 20px;background-color: #ffffff;color: #343a40;"></Badge></td>        
      </table>
    </div>
    <div class="container" v-if="isMobile == false" >   
      <!-- activeIndex : 기본으로 펼처지게 열리게 끔 할 Index -->
      <!-- <Accordion class="accordion-custom" :activeIndex="0"> -->
      <Accordion class="accordion-custom"   >
        <AccordionTab v-for="(row, i) in tracking" :key="i" >
          <template #header>    
            <span>
              <Tag class="p-tag p-component p-tag-info" :style="row.supportType.indexOf('라이선스발급') != -1 ? 'margin-right: 5px;background-color: #0060bf;' : 'margin-right: 5px;background-color: #198754;'">{{ row.supportNames }}</Tag>
              <Tag class="p-tag p-component p-tag-info" :style="tagStyle(row.status)">{{ row.status }}</Tag>
              {{  row.supportType.indexOf('라이선스발급') != -1 ? row.supportDate :  new Date(row.supportDate).toISOString().slice(0, 10) }}                
              <br v-if="isMobile" />
              <b :class="row.supportType.indexOf('라이선스발급') != -1 ? 'text-danger' : 'text-primary'" > ({{ row.supportType }})</b>    
              <span style="margin-left: 10px;font-size:small;">{{  row.supportType.indexOf('라이선스발급') != -1 ? '발급제품 : '+row.supportProd :  "" }}</span>
              <br/>
              <div v-if="isNullProject == true" style="text-align: left;font-size:small;">
                {{ row.companyName }}-{{ row.projectName }}
              </div>
            </span>
          </template>
          <p v-if="row.supportType.indexOf('라이선스발급') != -1"><B>[ 발급내용 ]</B><br>{{ row.responseDetails }}</p>
          <p v-if="row.supportType.indexOf('라이선스발급') == -1"><B>[ 지원내용 ]</B><br>{{ row.responseDetails }}</p>          
          <Button class="pi pi-search-plus" @click="popupModal(row)" rounded outlined />
        </AccordionTab>
      </Accordion>
    </div>
    <div class="container d-flex" v-if="isMobile == true" >
        <!-- activeIndex : 기본으로 펼처지게 열리게 끔 할 Index -->
      <Accordion class="accordion-custom" :activeIndex="0">
        <!-- <Accordion class="accordion-custom"   > -->
        <AccordionTab v-for="(row, i) in tracking" :key="i" >
          <template #header>    
            <span>
              <Tag class="p-tag p-component p-tag-info" :style="row.supportType.indexOf('라이선스발급') != -1 ? 'margin-right: 5px;background-color: #0060bf;' : 'margin-right: 5px;background-color: #198754;'">{{ row.supportNames }}</Tag>
              <Tag class="p-tag p-component p-tag-info" :style="tagStyle(row.status)">{{ row.status }}</Tag>
              {{  row.supportType.indexOf('라이선스발급') != -1 ? row.supportDate :  new Date(row.supportDate).toISOString().slice(0, 10) }}                
              <br v-if="isMobile" />
              <b :class="row.supportType.indexOf('라이선스발급') != -1 ? 'text-danger' : 'text-primary'" > ({{ row.supportType }})</b>    
              <span style="margin-left: 10px;font-size:small;">{{  row.supportType.indexOf('라이선스발급') != -1 ? '발급제품 : '+row.supportProd :  "" }}</span>
              <br/>
              <div v-if="isNullProject == true" style="text-align: left;font-size:small;">
                {{ row.companyName }}-{{ row.projectName }}
              </div>
            </span>
          </template>
          <p v-if="row.supportType.indexOf('라이선스발급') != -1"><B>[ 발급내용 ]</B><br>{{ row.responseDetails }}</p>
          <p v-if="row.supportType.indexOf('라이선스발급') == -1"><B>[ 지원내용 ]</B><br>{{ row.responseDetails }}</p>          
          <Button class="pi pi-search-plus" @click="popupModal(row)" rounded outlined />
        </AccordionTab>
      </Accordion>
    </div>

    <div class="container">
      <LoadingBar :loading= "loading" v-if="loading == true"></LoadingBar>
      <Pagination :pagination="pagination" @page-change="changePage" v-if="isMobile == false"></Pagination>
      <mPagination :pagination="pagination" @page-change="changePage" v-if="isMobile == true"></mPagination>
    </div>
    
    
  </template>
  
  <script>        
  import Pagination from '@/components/Common/ClipPagination.vue'
  import mPagination from '@/components/Common/mClipPagination.vue'
  import LicenseIssue from '../License/IssueNoRequest.vue'
  import SupportDetail from '../Support/SupportDetail.vue'
  import LoadingBar from '@/components/Common/LoadingBar.vue'
import { getCurrentInstance } from 'vue';

  export default {
    
    name: 'TrackingMain',
    data() {
      return {        
        //header: ["사업구분","고객사", "프로젝트","서버구분","제품","엔진ver","뷰어ver","디자이너ver","모듈ver","작업자","지원방법","지원구분","작업일자","요청내용","지원내용","지역","지원상태"],        
        header: ["사업구분","고객사", "프로젝트","서버구분","제품","작업자","지원방법","지원구분","작업일자","요청내용","지원내용","지역","지원상태"],        
        update: false,        
        searchKeywordCompany: "",
        searchKeywordProject: "",
        searchKeyword: '',
        companys: [],
        filteredCompanys: [],
        projects: [],
        filteredProjects: [],    
        currentPage: 1,        // 현재 페이지
        itemsPerPage: 10,      // 페이지당 항목 수
        total: 0,      // 페이지당 항목 수
        isMobile : this.$isMobile(),
        pagination: {
              endRow: 0,
              hasNextPage: false,
              hasPreviousPage: false,
              isFirstPage: true,
              isLastPage: true,
              navigateFirstPage: 0,
              navigateLastPage: 0,
              navigatePages: 10,
              navigatepageNums: [],
              nextPage: 0,
              pageNum: 1,
              pageSize: this.$isMobile() ? 5 : 10,
              pages: 0,
              prePage: 0,
              size: 0,
              startRow: 0,
              total: 0
          },      
        hoverText: '',
        tracking: {},       
        licenseIssueModal: false,   
        detailsProjectUid: '',
        detailsCompanySeq: '',
        detailsLicenseSeq: '',
        supportDetailModal: false,   
        supportDetailsKey: '',
        isAdmin: 'N',
        engineerRoleCheck: true,
        groupedCounts: [],
        isGroupedCounts: false,
        isNullProject: false,
        period: '',
        loading: false,
      };
    },
    props: {
    },
    components: {      
      Pagination,
      mPagination,
      LicenseIssue,
      SupportDetail,
      LoadingBar,
    },
    created() {   
      var param = {
      };
      
      this.$get('/biz/company/list',  {
                  params: param
              },
                  function(response, i){
                      i.companys = response.data.result;   //기존데이터에 추가
                  },
                  function(e, i){
                    console.log(e);
                  }
      );  
      this.$post(`/biz/account/${this.$store.getters.getUserEmail}/team/get`, null,
              function(response, i){
                i.isAdmin = response.data.adminYn;
              },
              function(e, i){
              	console.log(e);
              }
      );       
    },  
    watch: {    
      update(update) {      
        if (update == true) {
          var pageSize = this.isMobile ? 5 : 10;
          this.changePage(this.currentPage, pageSize);
          this.update = false;
        }
      } 
    },  
    computed: {
    },
    mounted() { 
      this.$nextTick(function() {
        this.getTeamAccount();    
      });     
    },
    methods: {      
      // 페이지 변경 시 호출될 함수      
      changePage(newPage, pageSize) {    
        this.isNullProject = this.searchKeywordProject.projectName == undefined ? true : false;
        this.isGroupedCounts = false;
        if(this.searchKeywordCompany == "" && this.isMobile == false){
          this.$messageShow('고객사를 입력해주세요.', 'warn');
        } else if(this.searchKeyword == "" && this.isMobile == true) {
          this.$messageShow('고객사 또는 프로젝트를 입력해주세요.', 'warn');
        } else {
          this.currentPage = newPage;        
          var paramData = {
            "pageNum": newPage,
            "pageSize": pageSize,
            "searchKeyword": this.searchKeyword,
            "searchKeywordCompany": this.searchKeywordCompany.companyName == undefined ? "" : this.searchKeywordCompany.companyName,
            "searchKeywordProject": this.searchKeywordProject.projectName == undefined ? "" : this.searchKeywordProject.projectName,
            };      
          this.loading = true;
          this.$get('/biz/tracking/list', {
                          params: paramData
                          },
                      function(response, i){
                          i.tracking = response.data.pagination.list;   //기존데이터에 추가
                          i.pagination = response.data.pagination;
                          if(i.pagination.total == 0) {
                            i.$messageShow('조회된 값이 없습니다.', 'warn');
                          } else {
                            i.groupedCounts = i.getGroupedCounts(i.pagination.list);    
                            const sDate = new Date(i.tracking[i.tracking.length-1].supportDate).toISOString().slice(0, 10);
                            const eDate = new Date(i.tracking[0].supportDate).toISOString().slice(0, 10);

                            i.period = sDate + ' ~ ' + eDate;
                            i.isGroupedCounts = true;
                          }                          
                          i.loading = false;
                      },
                      function(e, i){
                          console.log(e);
                          i.loading = false;
                      }
          );
        }
      },      
      unsetSearchData(){
          this.searchKeywordCompany = "";
          this.searchKeywordProject = "";
          this.period = "";
      },
      setHoverText(text) {
        this.hoverText = text;
      },
      clearHoverText() {
        this.hoverText = '';
      },  
      companySearch(event) {                
          //setTimeout(() => {
            if (!event.query.trim().length) {              
                this.filteredCompanys = [...this.companys];
            } else {              
                this.filteredCompanys = this.companys.filter((company) => {
                    return company.companyName.toLowerCase().includes(event.query.toLowerCase());
                });
            }
         // }, 250);
      },
      projectSearch(event) {
        setTimeout(() => {
            if (!event.query.trim().length) {
                this.filteredProjects = [...this.projects];
            } else {
                this.filteredProjects = this.projects.filter((project) => {
                    return project.projectName.toLowerCase().includes(event.query.toLowerCase());
                });
            }
        }, 250);            
      }, 
      setProjectList() {        
        this.searchKeywordProject = "";
        this.period = "";
        if(this.searchKeywordCompany.companySeq != undefined){                    
          var param = {
            companySeq : this.searchKeywordCompany.companySeq,
          };
          
          this.$get('/biz/project/list',  {
                        params: param
            },
                  function(response, i){
                  i.projects = response.data.result;   //기존데이터에 추가
                  },
                  function(e, i){
                    console.log(e);
                  }
          );
        }              
      },
      changeProject(){
        this.period = "";
      },
      popupModal(row){
        if(row.supportType.indexOf('라이선스발급') != -1){
          this.licenseIssueModal = true;
          this.detailsProjectUid = row.projectUid;
          this.detailsCompanySeq = row.coreKey;
          this.detailsLicenseSeq = row.coreKey2;
        } else {
          this.supportDetailModal = true;
          this.supportDetailsKey = row.coreKey;
        }
      }, 
      getTeamAccount() {
        var paramData = {
              "teamCode": "TST"
        };     
        this.$get('/biz/account/teamlist', {
                    params: paramData
                },
                    function(response, i){
                      i.supportNames = response.data.teamResult;   //기존데이터에 추가                  
                    },
                    function(e, i){
                      console.log(e);
                    }
        );
      },  
      tagStyle(status){
        if(status == '작업전') {
            return 'margin-right: 5px;background-color: #f59e0b';
        } else if(status == '미완료') {
            return 'margin-right: 5px;background-color: #dc3545;';            
        } else if(status == '완료') {
            return 'margin-right: 5px;background-color: #198754;';
        }else {
            return 'margin-right: 5px;background-color: #0060bf';
        }
      },
      getGroupedCounts(arr){
        const gCnt = arr.reduce((acc, curr) => {
          if (acc[curr.status]) {
            acc[curr.status]++;
          } else {
            acc[curr.status] = 1;
          }
          return acc;
        }, {});

        return gCnt;
      }
    },    
  };
  </script>
  
  <style lang="css" scoped>
  .container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 0px;
    justify-content: center
  }
  .container > * {
    margin-right: 10px;
    font-size: 15px;
  }
  .container > *:last-child {
    margin-right: 0;
  }
  .container .selects {
    display: flex;
  }
  .container .selects select {
    width: 120px;
    margin-right: 10px;
  }
  .container .selects select:last-child {
    margin-right: 0;
  }
  
  .center {
    text-align: center;
  }
  .container .btn {
    width: 120px;
    height: 50px;
    font-weight: 700;
    flex-shrink: 0;
  }
  @media only screen and (max-width: 480px) {
      .container {
        display: block;
      }
      .selects {
        margin-top: 10px;
      }
      .btn {
        margin-top: 10px;
      }
  }
  
  th{
    text-align: center;
  }
  
  .pi{
    min-width: 50px;
    width: 50px;
    min-height: 50px;
    height: 50px;
    padding: 0.75rem 1.05rem;
  }

  .accordion-custom {    
    width: 1000px;
  }
  </style>