// [vuex import 수행 실시]
import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import jwt from "./jwt"
// [store 데이터 설정 실시]
const store = createStore ({
  // count state 속성 추가
  state: {
    token: {
      accessToken: jwt.getToken(),
    }, // 토큰정보
    isAuthenticated: !!jwt.getToken(),
    isLogin: false,
    userEmail: null,
    saveEmailYn: null,
    role: null,
    name: null,
    
  },
  getters: {
    getAccessToken: function (state) {
      return state.token.accessToken
    },
    isAuthenticated: function (state) {
      return state.isAuthenticated
    },
    isLogined: function (state) {
      return state.isLogin
    },
    getUserEmail: function (state) {
      return state.userEmail
    },
    getSaveEmailYn: function (state) {
      return state.saveEmailYn
    },
    getRole: function (state) {
      return state.role
    },
    getName: function (state) {
      return state.name
    },
  },
  mutations: {
    logout: function (state, payload = {}) {
      state.token.accessToken = null
      state.isAuthenticated = false
      state.isLogin = false
      state.userEmail = null
      state.saveEmailYn = null
      state.role = null
      state.name = null
      jwt.destroyToken()
    },
    login: function (state, payload = {}) {
      state.token.accessToken = payload.accessToken
      state.isAuthenticated = true
      state.isLogin = true
      state.userEmail = payload.userEmail
      state.saveEmailYn = payload.saveEmailYn 
      state.role = payload.role
      state.name = payload.name
      jwt.saveToken(payload.accessToken);
       // userEmail을 쿠키에 저장
      if (state.saveEmailYn) {
        document.cookie = `saveEmailYn=${state.saveEmailYn}; path=/`;
        document.cookie = `userEmail=${state.userEmail}; path=/`;
      } else  {
        document.cookie = `saveEmailYn=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
        document.cookie = `userEmail=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
      }
     
    },
  },
  actions: {
    logout: function (context, payload) {
      return new Promise(resolve => {
        setTimeout(function () {
          context.commit("logout", payload)
          resolve({})
        }, 1000)
      })
    }
  },
  modules: {},
   plugins: [
    createPersistedState()
  ]
});

export default store;