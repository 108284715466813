<template>
	<Dialog v-model:visible="visible" maximizable modal header="사용자 정보 변경" :style="{ width: '35vw' }">
		  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
	      	<Divider align="left" type="solid">
		    	<b>이름</b>
			</Divider>
			<p>
			    <input type="text" class="form-control" placeholder="한글로 작성해주세요." v-model="name" />
			</p>
			<Divider align="left" type="solid">
		    	<b>이메일</b>
			</Divider>
			<p>
		        <input type="text" class="form-control" placeholder="영문으로 작성해주세요." v-model="newUserEmail"/>
			</p>
			<Divider align="left" type="solid">
		    	<b>사용자 여부</b>
			</Divider>
			<p>
			    <input class="ms-2" type="checkbox" id="userCheck" v-model="useYn" true-value="Y" false-value="N" />
			    <label for="userCheck">적용</label>
			</p>
			<Divider align="left" type="solid">
		    	<b>관리자 여부</b>
			</Divider>
			<p>
			    <input class="ms-2" type="checkbox" id="managerCheck" v-model="adminYn" true-value="Y" false-value="N"/>
			    <label for="managerCheck">적용</label>
			</p>
			
	      </div>
	      <template #footer>
	      	<Button label="삭제" icon="pi pi-times" @click="deleteData" text />
	        <Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
	        <Button label="저장" icon="pi pi-check" @click="updateData" autofocus />
	    </template>
	</Dialog>
</template>

<script>
export default {
  name: 'updateModal',
  data(){
    return{
      name: "",
      email: "",
      newUserEmail: "",
      useYn: "",
      adminYn: "",
      visible: true,
    }
  },
  props: {
    detailsEmail: String,
    update: Boolean
  },
  created(){
    var param = {
    };
    
    this.$post(`/biz/account/${this.detailsEmail}/get`, param,
	                function(response, i){
	                	i.email = response.data.userEmail;
	                	i.newUserEmail = response.data.userEmail;
	                	i.name = response.data.name;
	                	i.useYn = response.data.useYn;
	                	i.adminYn = response.data.adminYn;
	                },
	                function(e, i){
	                	console.log(e);
	                }
	 );
  },
  methods: {
    updateData() {
      	if(confirm("사용자 정보를 변경하시겠습니까?")){
	        if(this.name == "" || this.newUserEmail == "") {
	          this.$messageShow('이름, 이메일주소는 필수입력항목입니다.', 'warn');
	        }else{
	        
	        if(!this.$emailValidation(this.newUserEmail)){
	        	this.$messageShow('이메일주소가 형식에 맞지 않습니다.', 'warn');
	        }else{
	        
		        var param = {
			        "userEmail": this.email,
			        "newUserEmail": this.newUserEmail,
			        "name": this.name,
			        "useYn": this.useYn,
			        "adminYn": this.adminYn,
				};
				
				this.$post('/biz/account/mod', param,
	                function(response, i){
	                	//alert("사용자 정보 변경이 완료되었습니다.");
	                	i.$messageShow('사용자 정보 변경이 완료되었습니다.', 'success');
	                	i.visible = false;
	                	i.$emit('closesUpdateModal');
          				i.$emit('update');
	                },
	                function(e, i){
	                	i.$messageShow(e, 'danger');
	                }
	      		);
		 	}	        
	        
	      }

		}
    },
    deleteData() {
    	this.errorMessage = '';
    	
      	if(confirm("사용자 정보를 삭제하시겠습니까?")){
	        
	        var param = {
	        };
	        
	         this.$post(`/biz/account/${this.detailsEmail}/del`, param,
	                function(response, i){
	                	//alert("사용자 정보 삭제가 완료되었습니다.");
	                	i.$messageShow('사용자 정보 삭제가 완료되었습니다.', 'success');
	                	i.visible = false;
	                	i.$emit('closesUpdateModal');
          				i.$emit('update');
	                },
	                function(e, i){
	                	i.$messageShow(e, 'danger');
	                }
	 		);
		 }
    },
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>

