/**
 * 공통 글로벌 함수 
 */
import axios from 'axios';
import jwt from "./jwt";
import http from "./http";
import { indexOf } from 'lodash';
var requestOptions = {
        headers: {
            'Content-Type': 'application/json',
            timeout: 60000
        }
    };

export default{
    install(Vue){
    
    	// ajax post
        Vue.config.globalProperties.$post = function (callUrl, postData, succ, fail){
            var URL = callUrl;
            http.post(URL, postData).then((result) => {
                succ(result, this);
            }).catch(e => {
                fail(e, this);
            });
        }
        
        // Device Checker
        Vue.config.globalProperties.$isMobile = function (){
	          const info = navigator.userAgent;
		      var flag = false;
		
		      if( info.indexOf("iPhone") > -1
		          || info.indexOf("Android") > -1
		          || info.indexOf("iPad") > -1
		          || info.indexOf("iPod") > -1
		      ) {
		        flag = true;
		      }
		      
		      return flag;
        }
        
        // 코드 리스트 조회
        Vue.config.globalProperties.$code = function (categoryList, selectAll, succ, fail){
        
        	var paramData = {
		    	categoryList: categoryList,
                selectAll: selectAll
			};
        
            var URL = '/biz/common/code';
            http.get(URL, {
	            	params: paramData
	       		 }).then((response) => {
                	succ(response.data.result, this);
            }).catch(e => {
            this.$store
		            .dispatch("logout", {})
		            .then(() => {
		            	this.$router.replace('/');
		            })
		            .catch(({ message }) => alert(message));
              fail(e, this);
            });
        }
        
        // ajax get
        Vue.config.globalProperties.$get = function (callUrl, postData, succ, fail){
            var URL = callUrl;
            http.get(URL, postData).then((result) => {
                succ(result, this);
            }).catch(e => {
                fail(e, this);
            });
        }
        
        // 로그인
        Vue.config.globalProperties.$login = function (userEmail, password,	saveYn){
        	jwt.destroyToken();
            var URL = '/biz/common/login';
			var p_defaultPwYn = false;
            var postData = {
            	userEmail: userEmail,
            	password: password            
			}      
            axios.post(URL, postData, requestOptions).then((result) => {           
                if (result.status == 200) {	            
		            this.$store.commit("login", {
		            	accessToken: result.data.accessToken,
		            	userEmail:  result.data.userEmail,
						saveEmailYn:  saveYn,
					    role: result.data.authorities[0],
					    name: result.data.name,
						defaultPwYn: result.data.defaultPwYn
		            })		
					if(result.data.defaultPwYn) {
						alert("기본 패스워드는 변경이 필요합니다.")
						this.$router.push({
							name: "UserInfo",
							query: { 
								defaultPwYn:  result.data.defaultPwYn,
							},
						  });
					} else {
						this.$router.replace('/biz');		                     				
					}
		            
		        }
            }).catch(e => {
				if(e.response.data.errorMsg.indexOf('BizExceptionCode.') != -1){
					this.errorMessage = e.response.data.errorMsg.split('BizExceptionCode.')[1];
				} else {
					this.errorMessage = e.response.data.errorMsg;
				}
              	this.$refs.floatingInput.focus();
            });
        }
                
        // 로그아웃
        Vue.config.globalProperties.$logout = function (){
        	var URL = '/biz/common/logout';
            var postData = {};  
        	axios.post(URL, postData, requestOptions).then((result) => {           
                if (result.status == 200) {		            
		            this.$store
		            .dispatch("logout", {})
		            .then(() => {
		            	this.$router.replace('/');
		            })
		            .catch(({ message }) => alert(message));                     
		        }
            }).catch(e => {
              	alert(e.response.data.errorMsg);
            });
        }
        
        // 세션만료시 자동로그아웃
        Vue.config.globalProperties.$automatedLogout = function (e){
        	if(e.errorCode == 'HTTP403'){
               alert('세션이 만료되어 로그아웃됩니다.');
               var URL = '/biz/common/logout';
               var postData = {};  
               axios.post(URL, postData, requestOptions).then((result) => {           
                if (result.status == 200) {		            
		            this.$store
		            .dispatch("logout", {})
		            .then(() => {
		            	this.$router.replace('/');
		            })
		            .catch(({ message }) => alert(message));                     
			        }
	            }).catch(e => {
	              	alert(e.response.data.errorMsg);
	            });
           }
        	
        }

        //이메일 정규식
        Vue.config.globalProperties.$emailValidation = function(data){
            var regExp = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i;
            if(regExp.test(data)){
                //통과
                return true;
            }else{
                //올바르지 않음
                return false;
            }
        }
        
        //IP 정규식
        Vue.config.globalProperties.$checkIP = function(data){
            var regExp = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])|\*)$/i;
            if(regExp.test(data)){
                //통과
                return true;
            }else{
                //올바르지 않음
                return false;
            }
        }
        
        //홈페이지 정규식
        Vue.config.globalProperties.$homepageUrlValidation = function(data){
            var regExp = /^https?:\/\/[\w\-.]+$/i;
            if(regExp.test(data)){
                //통과
                return true;
            }else{
                //올바르지 않음
                return false;
            }
        }
        
        
	    Vue.config.globalProperties.$messageShow = function(message, severity) {
	            this.$toast.add({ severity: severity, summary: '확인', detail: message, life: 3000 });
	    }
        
        Vue.config.globalProperties.$simpleDateTimeFormat = function(date, pattern) {
			var days = ["일", "월", "화", "수", "목", "금", "토"];
			var dateString = pattern.replace(/(yyyy|MM|M|dd|d|HH|H|hh|h|mm|m|ss|s|SSS|a|EEE|eee)/g, function(match) {
				var matchString = "";
				switch(match) {
					case "yyyy":
						matchString = date.getFullYear();
						break;
					case "MM":
					case "M":
						matchString = date.getMonth() + 1;
						break;
					case "dd":
					case "d":
						matchString = date.getDate();
						break;
					case "HH":
					case "H":
						matchString = date.getHours();
						break;
					case "hh":
					case "h":
						var hours = date.getHours() % 12;
						matchString = (hours) ? hours : 12;
						break;
					case "mm":
					case "m":
						matchString = date.getMinutes();
						break;
					case "ss":
					case "s":
						matchString = date.getSeconds();
						break;
					case "SSS":
						matchString = date.getMilliseconds();
						break;
					case "a":
						matchString = (date.getHours() < 12) ? "오전" : "오후";
						break;
					case "EEE":
						matchString = days[date.getDay()] + "요일";
						break;
					case "eee":
						matchString = days[date.getDay()];
						break;
					default :
						matchString = match;
						break;
				}
				if (match == "SSS") {
					if (matchString < 10) {
						matchString = "00" + matchString;
					} else if (matchString < 100) {
						matchString = "0" + matchString;
					}
				} else {
					if (match != "M" && match != "d" && match != "H" && match != "h" && match != "m" && match != "s"
						&& match != "a" && match != "EEE" && match != "eee") {
						if ((typeof(matchString) == "number" && matchString < 10)) {
							matchString = "0" + matchString;
						}
					}
				}
				return matchString;
			});
		
			return dateString;
		}

		Vue.config.globalProperties.$calendar = function (){
			this.$primevue.config.locale.today = '오늘';
			this.$primevue.config.locale.clear = '지우기';
			this.$primevue.config.locale.dayNamesMin = ['일', ' 월', ' 화', ' 수', ' 목', ' 금', ' 토'];
			this.$primevue.config.locale.monthNames = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'];
			this.$primevue.config.locale.monthNamesShort = ['1월','2월','3월','4월','5월','6월','7월','8월','9월','10월','11월','12월'];
		}

    }
}
