<template>
  <transition name="fade" @closesModal="modal = false">
    <Password v-if="modal" :defaultPwYn="$route.query.defaultPwYn" />
  </transition>

  <div>
    <div class="wrap">
	  <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
	    <h1 class="h2">사용자 정보</h1>
	  </div>
		<div class="mb-2 pb-1 col-md-5">  
			<table class="table table-bordered border-secondary-subtle">
			<tbody>
				<tr>
					<th class="table-secondary col-md-3">
						이름
					</th>
					<td>
						{{ user.name }}
					</td>
				</tr>
				<tr>
					<th class="table-secondary col-md-3">
						이메일
					</th>
					<td>
						{{ user.userEmail }}
					</td>
				</tr>
				<tr>
					<th class="table-secondary col-md-3">
						시스템 사용권한
					</th>
					<td>
						{{ user.adminYn === 'Y' ? '관리자' : '일반사용자' }}
					</td>
				</tr>
			</tbody>
			</table>
		</div>
		<div class="mb-2 pb-1">
			<button type="button" class="btn btn-primary" @click="modal = true;">비밀번호 변경</button>
		</div>
    </div>
  </div>
</template>

<script>
import Password from './Password.vue'

export default {
  name: 'UserInfo',
  data() {
    return {
      user: {},
      modal: this.$route.query.defaultPwYn != 'true' ? false : true,
    };
  },
  components: {
    Password
  },
  created() {
    var paramData = {};
    this.$post(`/biz/account/${this.$store.getters.getUserEmail}/get`, paramData,
              function(response, i){
              	i.user = response.data;
              },
              function(e, i){
              	console.log(e);
              }
    );
  },
}
</script>

<style scoped>
.wrap {
  margin-top: 30px;
}
</style>