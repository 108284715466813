<template>

	<Dialog v-model:visible="visible" maximizable modal header="라이선스 상세" :style="{ width: modalWidth }" @update:visible="$emit('closesModal'); visible = false;">		
		  <div class="flex flex-wrap justify-content-left gap-2 mb-2">
			  <Divider align="left" type="solid">
				<b>고객사</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.companyName" disabled />
			</p>
			
			<Divider align="left" type="solid">
				<b>프로젝트</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.projectName" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>일련번호</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.licenseSerialNo" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>서버구분</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.serverType" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>효력발생일</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.startDate" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>만료일</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.endDate" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>CPU Core 수</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.cpuInfo" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>IP</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="details.ipAddress" disabled />
			</p>

			<Divider align="left" type="solid">
				<b>제품정보</b>
			</Divider>
			<p>
				<input type="text" class="form-control" v-model="productInfo" disabled />
			</p>

			
			
			<Divider align="left" type="solid">
					<b>key</b>
			</Divider>
			<p>
				<Textarea v-model="details.key" autoResize rows="4" disabled :style="{ 'font-family': 'Consolas,monaco,monospace' ,'min-width': '100%'  }"/>
			</p>
			<Divider align="left" type="solid">
					<b>비고</b>
			</Divider>
			<p>
				<Textarea v-model="details.licenseComment" autoResize rows="4" disabled :style="{ 'font-family': 'Consolas,monaco,monospace' ,'min-width': '100%'  }"/>
			</p>
		  </div>
		  <template #footer>
			<Button label="닫기" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
			<div class="flex align-items-center gap-2" style="margin: 15px 10px 0px 0px;">               
				<span class="p-text-secondary"><b>{{ details.modifierName == "" ? details.registerName : details.modifierName }}</b></span><br>          
				<span class="p-text-secondary"><div class="text-xs flex align-items-center text-700"><i class="pi pi-calendar mr-1 text-xs"></i> {{ details.registerDate }}</div></span>
			</div>    
		</template>		
	  </Dialog>
	</template>
	
	<script>
	import _ from "lodash";
	export default {
	  name: 'LicenseInfoUpdateModal',
	  data(){
		return{
		  companys: [],
		  filteredCompanys: [],   
		  filteredIssuedProds: [],
		  issuedProds: [],
		  projects: [],
		  filteredProjects: [],
		  filteredRequestDeptEmails: [],
		  ipTypes: [],
		  serverType: "",
		  serverTypes: {},
		  periodTypes: {},
		  licenseVersions: {},
		  company: "",
		  project: "",
		  year: "",
		  issuedProd: "",
		  partnerName: "",
		  issueReqInfoYn: 'Y',
		  issueReqInfo: "",
		  cpuInfo: "",
		  ipType: "MULTI",
		  ipRows: [],
		  cloudYn: "",
		  startDate: "",
		  endDate: "",
		  periodType: "TIME_LIMIT",
		  licenseVersion: "V3",
		  visible: true,
		  visible2: false,
		  modalWidth: this.$isMobile() ? '100vw' : '35vw',
		  innerModalvisible: false,
		  licenseComment: "",
		  issuedLicense: "",
		  etc: "",
		  issueStatusType: "",
		  partnerYn: "",
		  licenseSerialNo: "",
		  processing: false,
		  requestInfo : "",
		  modifierName: "",
		  modDate: "",
		  registerName: "",
		  regDate: "",	
		  details: {},	 
		  productInfo: "", 
		}
	  },
	  props: {		 
		  detailsLicenseSeq: Number,
	  },
	  components: {
	  },
	  created(){			
		
	  },
	  mounted(){
			this.$nextTick(function() {
				this.setLicenseInfo();
				  
			});
	  },
	  methods: {
		setLicenseInfo(){
	
			this.$get(`/biz/licenseold/get`, {
				params: {									
						seq : this.detailsLicenseSeq,
					}
				},
			function(response, i){
				var detail = response.data.detail;
				i.details = detail;

				var r3 = i.details.r3;
				var r4 = i.details.r4;
				var r5 = i.details.r5;
				var e25 = i.details.e25;
				var e4 = i.details.e4;
				var e5 = i.details.e5;

				var productInfoStr = "";
				productInfoStr += (r3 == 1 ? ", R3" : "");
				productInfoStr += (r4 == 1 ? ", R4" : "");
				productInfoStr += (r5 == 1 ? ", R5" : "");
				productInfoStr += (e25 == 1 ? ", E2.5" : "");
				productInfoStr += (e4 == 1 ? ", E4" : "");
				productInfoStr += (e5 == 1 ? ", E5" : "");
				
				i.productInfo = productInfoStr.substring(0,2) == ', ' ? productInfoStr.substring(2,productInfoStr.length) : productInfoStr;
			},
			function(e, i){
				console.log(e);
			}
		  );		  
		},		
	  }
	}
	</script>
	
	<style scoped>
	.black-bg {
	  width: 100%; 
	  height: 100%;
	  background: rgba(0,0,0,0.5);
	  position: fixed;
	  padding: 20px;
	}
	.white-bg {
	  width: 400px;
	  background: white;
	  border-radius: 8px;
	  padding: 20px;
	  margin: 0 auto;
	  transform: translate(-50%, 5%);
	}
	h4 {
	  padding-bottom: 10px;
	  margin-bottom: 20px;
	  border-bottom: 1px solid rgb(169, 168, 168);
	}
	.check {
	  margin-left: 40px;
	}
	</style>
	
	