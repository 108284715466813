import axios from "axios"
import store from "./store"

const http = axios.create({
  baseURL: "",
  headers: { "content-type": "application/json" },
  timeout: 300000,
  withCredentials: true,
	paramsSerializer: function(paramObj) {
	    const params = new URLSearchParams()
	    for (const key in paramObj) {
	        params.append(key, paramObj[key])
	    }
	
	    return params.toString()
	}
})

http.interceptors.request.use(
  config => {
    const isAuthenticated = store.getters["isAuthenticated"]
    if (isAuthenticated) {
      config.headers["Authorization"] = "Bearer " + store.getters["getAccessToken"]
    }
    return config
  },
  error => {
    // Do something with request error
    Promise.reject(error)
  }
)

http.interceptors.response.use(
  config => {
    return config
  },
  error => {
   return new Promise((resolve, reject) => {
            const originalReq = error.config;
            console.log(error);
            if (
                error.response.status === 403 &&
                error.config &&
                !error.config.__isRetryRequest
            ) {
                originalReq.__isRetryRequest = true;				
				var URL = '/biz/common/valid';				        
                const valid = axios.post(URL).then((response) => {
                    if(response.data.userEmail !== "anonymous") {
                        	store.commit("login", {
				            	accessToken: response.data.accessToken,
				            	userEmail:  response.data.userEmail,
							    role: response.data.authorities[0]
			            	})
                        originalReq.headers.Authorization = "Bearer " + store.getters["getAccessToken"];
                        return http(originalReq);
                    } else {
                    	//alert('로그인 정보가 만료되어 로그아웃 됩니다.');
                        store
			            .dispatch("logout", {})
			            .then(() => {
			            	location.replace('/') 
			            })
			            .catch(({ message }) => alert(message));  
                    }
                });
        
                resolve(valid);
            }
    
            return reject(error);
        });
  }
)

http.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded"

export default http