<template>
<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
    <h1 class="h2">{{ detail.menuName }} 메뉴 상세</h1>
</div>

<nav class="navbar navbar-expand-lg bg-body-tertiary">
  <div class="container-fluid">
    <span class="h4" href="#"> {{ detail.menuName }} </span>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" :class="{ active: menuDetail === true }" aria-current="page" href="#" @click="menuInfor()">메뉴상세정보</a>
        </li>
        <li class="nav-item" v-if="detail.systemType == 'U'">
          <a class="nav-link" href="#"  :class="{ active: userDetail === true }" @click="userInfor(detailMembers.permissionType)">메뉴접근권한</a>
        </li>
      </ul>
    </div>
    <div class="d-lg-flex col-lg-3 justify-content-lg-end">
	    <button class="btn btn-primary" @click="this.$router.push('/biz/menu')">뒤로</button>
	</div>
  </div>
</nav>
<div class="container">	    
	<table class="table table-bordered border-secondary-subtle caption-top" v-if="menuDetail">
        <tbody>
	     <tr>
          <th class="table-secondary col-md-2">메뉴 이름</th>
          <td>{{ detail.menuName }}</td>
        </tr>
        <tr>
          <th class="table-secondary col-md-2">메뉴 번호</th>
          <td>{{ detail.menuSeq }}</td>
        </tr>
        <tr>
          <th class="table-secondary col-md-2">시스템타입</th>
          <td v-if="detail.systemType == 'A'">관리</td>
          <td v-else>사용자</td>
        </tr>
        <tr>
          <th class="table-secondary col-md-2">사용여부</th>
          <td v-if="detail.useYn == 'Y'">일반</td>
          <td v-else>정지</td>
        </tr>
        <tr>
          <th class="table-secondary col-md-2">메뉴 URL</th>
          <td>{{ detail.menuUrl }}</td>
        </tr>
        <tr>
          <th class="table-secondary col-md-2">생성일자</th>
          <td>{{ detail.regDate }}</td>
        </tr>
      </tbody>
	</table>
</div>

  <div v-if="userDetail">
    <div class="container">
    	<input
    	  id="searchInput"
	      v-model="searchText"
	      class="form-control"
	      type="text"
	      placeholder="이름 / 이메일" 
	       @input="performSearch" />
    </div>
    <div class="container">
	    <table class="table table-bordered table-hover caption-top table-striped mb-0">
	       <thead class="table-light">
		      <tr class="table-primary">
	          <th scope="col" class="center" v-for="item in header" :key="item">
	            {{ item }}
	          </th>
	        </tr>
	      </thead>
	      <tbody class="table-group-divider">
	        <tr  v-for="(detailMember, i ) in filteredDetailMembers" :key="i">
	          <td class="center">{{ detailMember.name }}</td>
	          <td class="center">{{ detailMember.userEmail }}</td>
	          <td class="center">
	            <input type="checkbox" id="checkbox" v-model="type[i]" :ref="'permissionYN'+i"
	              :checked="detailMember.permissionType !== '' && detailMember.permissionType !== null"
	              @click="types(detailMember.userEmail, detail.menuSeq, i)" />
	          </td>
	          <td class="center">
	             <input type="radio" v-model="detailMember.permissionType" value="A" :id="i+'A'" :ref="i+'A'"
	              :disabled="type[i] == false || detailMember.permissionType === null" @click="permissionType(
	                detailMember.userEmail, detail.menuSeq, 'A'
	              )">
	              <label :for="i+'A'">관리</label>&nbsp;&nbsp;
	              
	             <input type="radio" v-model="detailMember.permissionType" value="V" :id="i+'V'" :ref="i+'V'" 
	              :disabled="type[i] == false || detailMember.permissionType === null" @click="permissionType(
	                detailMember.userEmail, detail.menuSeq, 'V'
	              )">
	               <label :for="i+'V'">조회</label>
	          </td>
	        </tr>
	      </tbody>
	    </table>
	 </div>
  </div>

 
</template>

<script>
export default {
  name: "DtldMngmn",
  data() {
    return {
      menuSeq: this.$route.query.menuSeq,
      header: ['이름', '이메일', '권한부여여부', '권한 구분'],
      detail: {},
      detailMembers: {},
      menuDetail: true,
      userDetail: false,
      userInforButton: true,
      searchText: '',
      type: []
    }
  },
  created() {
    var paramData = {
    };
    
    this.$get(`/biz/menu/${this.menuSeq}`, paramData,
	                function(response, i){
	                	console.log(response.status);
	                	i.detail = response.data.detail;
	                },
	                function(e, i){
	                	console.log(e);
	                }
    );
      
     this.$get(`/biz/menu/auth/${this.menuSeq}`, paramData,
	                function(response, i){
	                	console.log(response.status);
	                	i.detailMembers = response.data.result;
	                },
	                function(e, i){
	                	console.log(e);
	                }
    );
  },
  computed: {
    //검색기능
    filteredDetailMembers() {
      if (!this.searchText) {
        return this.detailMembers;
      }
      const searchTextLower = this.searchText.toLowerCase();
      return this.detailMembers.filter((detailMember) =>
        detailMember.name.toLowerCase().includes(searchTextLower) ||
        detailMember.userEmail.toLowerCase().includes(searchTextLower)
      )
    },
  },
  methods: {
    menuInfor() {
      this.menuDetail = true;
      this.userDetail = false;
    },
    userInfor() {
      this.userDetail = true;
      this.menuDetail = false;
    },
    permissionType(email, menuSeq, permissionType) {

	    var paramData = {
	          "list": [
	            {
	              "userEmail": email,
	              "menuSeq": menuSeq,
	              "permissionType": permissionType
	            }
	          ]
	    };
        
	    this.$post('/biz/menu/auth/mod', paramData,
	                function(response, i){
	                	console.log(response.status);
	                },
	                function(e, i){
	                	console.log(e);
	                }
		);
        
    },
    types(userEmail, menuSeq, i) {
    	
    	var paramData = {
	          "list": [
	            {
	              "userEmail": userEmail,
	              "menuSeq": menuSeq,
	              "permissionType": 'V'
	            }
	          ]
	    };
    
      if(this.$refs['permissionYN'+i][0].checked){
      	this.$refs[i+'A'][0].disabled = false;
      	this.$refs[i+'V'][0].disabled = false;
      	this.$refs[i+'V'][0].checked = true;
      	//권한추가 API 호출
      	this.$post(`/biz/menu/auth/${this.menuSeq}/add`, paramData,
	                function(response, i){
	                	console.log(response.status);
	                },
	                function(e, i){
	                	console.log(e);
	                }
		);
      }else{
      	this.$refs[i+'A'][0].disabled = true;
      	this.$refs[i+'V'][0].disabled = true;
      	this.$refs[i+'A'][0].checked = false;
      	this.$refs[i+'V'][0].checked = false;
      	//권한삭제 API 호출      	
      	this.$post('/biz/menu/auth/del', paramData,
	                function(response, i){
	                	console.log(response.status);
	                },
	                function(e, i){
	                	console.log(e);
	                }
		);
      }
    }
  }
};
</script>
<style lang="css" scoped >
.container {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 0px;
  justify-content: center
}
.container > * {
  margin-right: 10px;
  font-size: 15px;
}
.container > *:last-child {
  margin-right: 0;
}
.container .selects {
  display: flex;
}
.container .selects select {
  width: 120px;
  margin-right: 10px;
}
.container .selects select:last-child {
  margin-right: 0;
}

.center {
  text-align: center;
}
.container .btn {
  width: 120px;
  height: 50px;
  font-weight: 700;
  flex-shrink: 0;
}
@media only screen and (max-width: 480px) {
	.container {
	  display: block;
	}
	.selects {
	  margin-top: 10px;
	}
	.btn {
	  margin-top: 10px;
	}
}

th{
  text-align: center;
}
tr{
  cursor: pointer;
}
</style>

