<template>
	<Dialog v-model:visible="visible" maximizable="true" modal header="고객사 정보 변경" :style="{ width: modalWidth }" @update:visible="$emit('closesModal'); visible = false;" >
		<div class="d-flex">						
			<Button label="히스토리" @click="visible2 = true" />			
		</div>		
		<Dialog v-model:visible="visible2" modal header="히스토리" :style="{ width: modalWidth }" :breakpoints="{ '1199px': '50vw', '575px': '90vw' }">	
			<Timeline :value="companyHistoryList">
				<template #opposite="left" >						
					<small class="p-text-secondary">{{ left.item.regDate.substring(0,11) }}{{ left.item.regDate.substring(11,16) }}<br>{{ left.item.userName }}</small>				
				</template>			
				<template #content="right">
					<template v-for="(field, index) in Object.keys(getNextCompanyName(historyHeader, right.item)).map((key) => key)" :key="index">
						<tr v-if="index >= 2 && getNextCompanyName(historyHeader, right.item)[field].split('|*|')[1] !== ''">
							<td nowrap><small class="p-text-secondary"><B>{{ getNextCompanyName(historyHeader, right.item)[field].split('|*|')[0] }}</B></small></td>
							<td nowrap style="max-width: 100px;"><small class="p-text-secondary" v-html="addNewLine(getNextCompanyName(historyHeader, right.item)[field].split('|*|')[1])"></small></td>
						</tr>
					</template>
				</template>
			</Timeline>
		</Dialog>
	    <div class="flex flex-wrap justify-content-left gap-2 mb-2">
      	<Divider align="left" type="solid">
	    	<b>고객사명</b>
		</Divider>
		<p>
		    <input type="text" class="form-control" v-model="companyName" v-on:mouseleave="callCheck" />
		</p>
		<Divider align="left" type="solid">
	    	<b>사업구분</b>
		</Divider>
		<p>
	        <Dropdown v-model="businessType" :options="codes" optionLabel="label" placeholder="사업구분을 선택해 주세요." class="w-full md:w-14rem" />
		</p>
		<Divider align="left" type="solid">
	    	<b>홈페이지 주소</b>
		</Divider>
		<p>
		    <input type="text" class="form-control" v-model="homepageUrl" />
		</p>
		<p>
			ex). https://www.raumsoft.co.kr 
		</p>		
      </div>
	  <div class="flex flex-wrap justify-content-center gap-2 mb-2">
      	<b style="color:red;font-size:14px;">{{errorMessage}}</b>
      </div>	 	  
      <template #footer>				
        <Button label="취소" icon="pi pi-times" @click="$emit('closesModal'); visible = false;" text />
        <Button label="저장" icon="pi pi-check" @click="detailsUpdate(this.companySeq)" autofocus />		
            <div class="flex align-items-center gap-2" style="margin: 15px 10px 0px 0px;">               
				<span class="p-text-secondary"><b>{{ modifierName == "" ? registerName : modifierName }}</b></span><br>          
            	<span class="p-text-secondary"><div class="text-xs flex align-items-center text-700"><i class="pi pi-calendar mr-1 text-xs"></i> {{ modDate == null ? regDate : modDate }}</div></span>
			</div>        			
      </template>
  </Dialog>
</template>

<script>
export default {
  name: 'CompanyInfoUpdateModal',
  data(){
    return{
      details: {},
      codes: {},
      companys: [],
      companyName: "",
      businessType: "",
      homepageUrl: "",
      visible: true,
	  visible2: false,
      modalWidth: this.$isMobile() ? '100vw' : '35vw',
	  modifierName: "",
      modDate: "",
	  registerName: "",
      regDate: "",
	  companyHistoryList: [],
	  historyHeader: ["수정일", "수정한사람", "고객사", "홈페이지", "사업구분"],	 
	  errorMessage : "", 
    }
  },
  props: {
    detailsCompanySeq: Number,
    update: Boolean
  },
  components: {
  },
  created(){
  
    var param = {};
    
    this.$get(`/biz/company/${this.detailsCompanySeq}/get`, param,
	                function(response, i){
						i.details = response.data.detail;						
	                	var detail = response.data.detail;
	                	i.companyName = response.data.detail.companyName;
	                	i.homepageUrl = response.data.detail.homepageUrl;
						i.modifierName = detail.modifierName;
						i.modDate = detail.modDate;
						i.registerName = detail.registerName;
						i.regDate = detail.regDate;						
	                	i.$code(["BUSINESS_TYPE"], false,
						function(result, i){
				           i.codes = result.businessType;
				           result.businessType.forEach(function(businessType, index) {
			                	if(detail.businessType === businessType.value){
			                		i.businessType = businessType;
			                	}
							});
				        },
				        function(e, i){
				        	console.log(e);
				        }
					);
	                	
	                },
	                function(e, i){
	                	console.log(e);
	                }
	 );
	 
	 this.$get('/biz/company/list',  {
	            	params: param
	       		 },
                function(response, i){
                   	i.companys = response.data.result;   //기존데이터에 추가
                },
                function(e, i){
                	console.log(e);
                }
     );

	 this.$get(`/biz/company/${this.detailsCompanySeq}/getH`, param,
	                function(response, i){
	                	var companyHistoryList = response.data.detailHistory;						
						for(var j = 0; j < companyHistoryList.length; j++){
							i.companyHistoryList.push(companyHistoryList[j]);
						}							
						i.companyHistoryList.sort((prev, cur) => {  // name 기준 내림차순 정렬 < > / 오름차순 > <
							if (prev.regDate < cur.regDate) return 1;
							if (prev.regDate > cur.regDate) return -1;
							});						
	                },
	                function(e, i){
	                	console.log(e);
	                }					
	 );
  },
  methods: {
    detailsUpdate(companySeq) {
    	var checked = false;
		const regex = /[^가-힣0-9]/g;
      	if(confirm("고객사 정보를 변경하시겠습니까?")){
	        if(this.companyName == "") {
	          this.$messageShow('고객사명은 필수입력항목입니다.', 'warn');
	          return checked;
			}else if (regex.test(this.companyName)) {
				this.$messageShow('특수문자, 영문, 공백은 입력할 수 없습니다.', 'warn');
				checked = false;
	        	return checked;
	        }else if(this.businessType == "") {
	          this.$messageShow('사업구분은 필수입력항목입니다.', 'warn');
	          return checked;
	        }else{
	        	var companyName = this.companyName;
				var businessName = this.businessType.codeName;
				var homepageUrl = this.homepageUrl;
	        	if (companyName == this.details.companyName && businessName == this.details.businessTypeName && homepageUrl == this.details.homepageUrl) {
					checked = false;
					this.$messageShow('기존 정보와 같습니다.', 'warn');
					return checked;
				} else if(this.companys.length > 0){
		        	for(let i=0; i<this.companys.length; i++){
			        	if(this.detailsCompanySeq != this.companys[i].companySeq){
			        		if(companyName != this.companys[i].companyName){
							  	checked = true;
							  }else{
							  	checked = false;
							  	this.$messageShow('이미 등록된 고객사정보입니다.', 'warn');
								return checked;
							  }
			        	}else{
			        		checked = true;
			        	}
					}
				} else{
					checked = true;
				}
	     	}
	     	
	     	if(checked){
        		var param = {
		          "companyName": this.companyName ,
		          "businessType": this.businessType.value,
		          "homepageUrl" : this.homepageUrl,
		          "companySeq" : this.detailsCompanySeq,
		          "modifierEmail" : this.$store.getters.getUserEmail
		        };
		        
		        this.$post('/biz/company/mod', param,
			                function(response, i){
			                	i.$messageShow('저장이 완료되었습니다.', 'success');
			                	i.visible = false;
			                	i.$emit('closesModal');
		          				i.$emit('update');
			                },
			                function(e, i){
			                	console.log(e);
			                	i.$messageShow(e, 'danger');
			                }
			 	);
        	}
	        
      }
    }, getNextCompanyName(header, item) {   
		console.log(this.companyHistoryList);
		console.log(this.companyHistoryList.length - 1)
		console.log(header.length)
		const currentIndex = this.companyHistoryList.indexOf(item);		
			
		var properties = Object.keys(item).map((key) => key);
		var rtValue = {};
		
		if (currentIndex < this.companyHistoryList.length - 1) {		
			for (let i = 0; i < header.length; i++) {
				let property = properties[i];			
				rtValue[property] = (this.companyHistoryList[currentIndex][property] == this.companyHistoryList[currentIndex + 1][property] ? "" : header[i] + '|*|' + (this.companyHistoryList[currentIndex][property] != null ? this.companyHistoryList[currentIndex][property] : ""));
			}
		} else {			
			for (let i = 0; i < header.length; i++) {
			let property = properties[i];			
			rtValue[property] = header[i] + '|*|' + (this.companyHistoryList[currentIndex][property] != null ? this.companyHistoryList[currentIndex][property] : "");
			}
		}
		return rtValue;
	},
	addNewLine(value) {						
		if(value != undefined && value != null) {				
			if(value.indexOf('.') == -1 && value.indexOf('allip') == -1)
				return value;
			else
				return value.replace(/,/g, '<br>');
				
		}						
	},
	callCheck() {
		if(this.companyName != "") {			
			const regex = /[^가-힣0-9]/g;
			if (regex.test(this.companyName)) {
				this.errorMessage = "특수문자, 영문, 공백은 입력할 수 없습니다.";				
			} else {
				this.errorMessage = "";
			}
		}
  	},
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
</style>

