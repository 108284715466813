<template>
    <Paginator :rows="5" :totalRecords="this.pagination.total"  template="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink" @page="onMoveNewPage">
	</Paginator>
</template>
<script>
import Paginator from 'primevue/paginator';
export default {
  data() {
      return {
      }
  },
  props: {
    pagination: {},
  },
  components: {
  	Paginator, 
  },
  created(){},
  methods: {
    onMoveNewPage(state){
        this.$emit('page-change', state.page+1, state.rows);
    },
  },
};
</script>

<style scoped>
</style>
