<template>
  <Dialog v-model:visible="visible" modal header="과거 고객사명" style="{ width: 50vw }" @update:visible="$emit('closesModal'); visible = false;" >  
    <Timeline :value="comapnys" class="w-full md:w-20rem"  >
      <template #opposite="left" >						
        <td style="max-width: 0px;">{{ left.item.Record }}</td>						
      </template>			
      <template #content="right">    
        <td v-if="right.index === 0" nowrap style="max-width: 200px; font-size: 18px;"><u><b>{{ right.item}}</b></u></td>	
        <td v-else nowrap style="max-width: 300px;">{{ right.item }}</td>          
			</template>			
    </Timeline>
  </Dialog>
</template>

<script>

export default {
  name: 'formerCompany',
  data(){
    return{
      visible: true,    
      formerCompany: "",
      comapnys: {},
      
      projectUid:"",
      companySeq:"",
      projectName:"",
      projectSerialNo: "",
      year:"",
      salesRepresentativeEmail:"",
      partnerYn:"",
      partnerName : "",  
      project_mngSeq:"",
      registerEmail:"",
      modifierEmail:"",
      useYn:"",

    }
  },
  props: {
  	detailsFormerCompany: String,
  },
  components: {
  },
  created(){
    this.formerCompany = this.detailsFormerCompany;
    if ( this.formerCompany != "" ) {
        this.comapnys = this.formerCompany.split(",");
    }
  },
  mounted(){
  		
  },
  methods: {
     update() {
      
     

    
    },
  }
}
</script>

<style scoped>
.black-bg {
  width: 100%; 
  height: 100%;
  background: rgba(0,0,0,0.5);
  position: fixed;
  padding: 20px;
}
.white-bg {
  width: 400px;
  background: white;
  border-radius: 8px;
  padding: 20px;
  margin: 0 auto;
  transform: translate(-50%, 5%);
}
h4 {
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(169, 168, 168);
}
.check {
  margin-left: 40px;
}
.p-timeline-event-opposite {
    flex: 0;
    padding: 0 1rem;  
}
.p-timeline {
  align-items : baseline;
}
</style>

